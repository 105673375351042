import curry from 'lodash/curry';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { expandField } from 'app/actions/SearchActions';
import { BROWSE_CATEGORY_PAGE_FACET_FIELD_IDS, FIELD_ID, GROUP_ID } from 'app/constants/BrowseConstants';
import { mapSearchBibToDisplayBib } from 'app/helpers/displayBib';
import {
  makeClearedSearchParams,
  makeFieldLabel,
  makeFilterLabel,
  makeGroupLabel,
  makeNewSearchParams,
  mapCatalogSearchFieldsToNewFacets,
  mapQuerySearchParamsToCatalogSearchFilters
} from 'app/helpers/browse';
import { selectBibEntities } from 'app/selectors/EntitiesSelector';
import { selectShowMoreFiltersFieldId } from 'app/selectors/SearchSelector';
import { categoryShape } from 'app/shapes/browse';
import FilterableDisplayBibGrid from '../shared/FilterableDisplayBibGrid';

const BrowseCategoryLeafPageContainer = ({ category }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const intl = useIntl();
  const { search } = useLocation();
  const fields = useSelector(state => state.getIn(['search', 'catalogSearch', 'fields']));
  const isFetchingSearchResults = useSelector(state => state.getIn(['search', 'catalogSearch', 'isFetching'])) ?? false;
  const isFetchingMoreFilters =
    useSelector(state => state.getIn(['search', 'catalogSearch', 'isFetchingShowMoreFilters'])) ?? false;
  const pagination = useSelector(state => state.getIn(['search', 'catalogSearch', 'pagination']));
  const searchBibs = useSelector(selectBibEntities);
  const searchResults = useSelector(state => state.getIn(['search', 'catalogSearch', 'results']))?.toJS() ?? [];
  const showMoreFieldId = useSelector(selectShowMoreFiltersFieldId);

  const isFetching = isFetchingSearchResults || isFetchingMoreFilters;
  const searchParams = new URLSearchParams(search);
  const appliedFiltersAccessibilityHeading = intl.formatMessage({ id: 'browse_active_filters' });
  const facetsHeading = intl.formatMessage({
    defaultMessage: 'TODO: What message here?',
    id: 'browse_facets'
  });

  const displayBibs = searchResults
    .map(({ representative: metadataId }) => searchBibs.get(metadataId)?.toJS() ?? null)
    .map(mapSearchBibToDisplayBib);

  const formatFieldLabel = curry(makeFieldLabel)(intl);
  const facetFields = mapCatalogSearchFieldsToNewFacets(fields.toJS(), {
    formatFieldLabel,
    formatFilterLabel: curry(makeFilterLabel)(intl),
    formatGroupLabel: curry(makeGroupLabel)(intl),
    formatShowMoreOverlayTitle: fieldId => {
      const fieldLabel = formatFieldLabel({ fieldId });
      return intl.formatMessage({ defaultMessage: 'All {fieldLabel}', id: 'all_field_filters' }, { fieldLabel });
    },
    orderedFieldIds: BROWSE_CATEGORY_PAGE_FACET_FIELD_IDS,
    orderedGroupIdsByFieldId: {
      [FIELD_ID.FORMAT]: [
        GROUP_ID.BOOKS,
        GROUP_ID.AUDIOBOOKS_SPOKEN_WORD,
        GROUP_ID.MOVIES_TV_VIDEO,
        GROUP_ID.MUSIC_SOUND,
        GROUP_ID.GAMES_INTERACTIVE_MEDIA,
        GROUP_ID.JOURNALS_MAGAZINES,
        GROUP_ID.ACCESSIBLE_FORMATS,
        GROUP_ID.OTHER
      ]
    },
    searchParams,
    showMoreFieldId
  });

  const handleFilterChange = (filter, newIsApplied) => {
    const newSearchParams = makeNewSearchParams(searchParams, filter, newIsApplied);

    push(`?${newSearchParams.toString()}`);
  };

  const setShowMoreFieldId = fieldId => {
    const baseSearchQuery = {
      ...category?.queryParams,
      limit: 18,
      view: 'grouped'
    };

    dispatch(
      expandField(fieldId, {
        ...baseSearchQuery,
        ...mapQuerySearchParamsToCatalogSearchFilters(searchParams, {
          fieldIds: BROWSE_CATEGORY_PAGE_FACET_FIELD_IDS
        })
      })
    );
  };

  const unapplyAllFilters = () => {
    const newSearchParams = makeClearedSearchParams({
      fieldIds: BROWSE_CATEGORY_PAGE_FACET_FIELD_IDS,
      oldSearchParams: searchParams
    });

    push(`?${newSearchParams.toString()}`);
  };

  return (
    <div className="container">
      <FilterableDisplayBibGrid
        appliedFiltersAccessibilityHeading={appliedFiltersAccessibilityHeading}
        displayBibs={displayBibs}
        facetFields={facetFields}
        facetsHeading={facetsHeading}
        handleFilterChange={handleFilterChange}
        hasBibRatings
        isFetching={isFetching}
        pagination={pagination}
        setShowMoreFieldId={setShowMoreFieldId}
        unapplyAllFilters={unapplyAllFilters}
      />
    </div>
  );
};

BrowseCategoryLeafPageContainer.propTypes = {
  category: categoryShape.isRequired
};

export default BrowseCategoryLeafPageContainer;
