import Immutable from 'immutable';
import React from 'react';
import { InView } from 'react-intersection-observer';
import uuidv4 from 'uuid/v4';

import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import ListCard from '@bibliocommons/list-card';

import { listRowConfigShape } from 'app/shapes/browse';

import './ListsRow.scss';

const makeImpressionId = (listId, impressionIdSuffix) => `${listId}-${impressionIdSuffix}`;

const ListsRow = ({ config }) => {
  const {
    lists,
    title,
    trackListCreatorClick,
    trackListImageClick,
    trackListImpression,
    trackListTitleClick,
    usersByListId
  } = config;
  if (!lists.length) {
    return null;
  }

  const primaryListUuid = uuidv4();
  const library = Immutable.Map();
  const primaryList = lists[0];
  const immutablePrimaryList = Immutable.Map(primaryList);
  const primaryListCreatorUser = Immutable.Map(usersByListId[primaryList.id]);
  const secondaryLists = lists.slice(1);

  const handleVisibility = (itemPosition, list, impressionIdSuffix) => {
    return isVisible => {
      if (isVisible) {
        trackListImpression({ impressionId: makeImpressionId(list.id, impressionIdSuffix), itemPosition, list });
      }
    };
  };

  return (
    <div className="cp-browse-lists-row">
      <ScreenReaderMessage>
        <h2>{title}</h2>
      </ScreenReaderMessage>
      <div className="row">
        <div className="browse-lists-row__primary col-md-7">
          <InView threshold={1} triggerOnce onChange={handleVisibility(1, primaryList, primaryListUuid)}>
            <ListCard
              creator={primaryListCreatorUser}
              handleListImageClick={() =>
                trackListImageClick({
                  impressionId: makeImpressionId(primaryList.id, primaryListUuid),
                  itemPosition: 1,
                  list: primaryList
                })}
              handleListTitleClick={() =>
                trackListTitleClick({
                  impressionId: makeImpressionId(primaryList.id, primaryListUuid),
                  itemPosition: 1,
                  list: primaryList
                })}
              largeImage
              library={library}
              list={immutablePrimaryList}
              showDescription
              showUserBadge
              titleTag="h3"
              trackCreatorClick={creatorName => {
                trackListCreatorClick({ creatorName, itemPosition: 1, list: primaryList });
              }}
            />
          </InView>
        </div>

        <div className="browse-lists-row__secondary col-md-5">
          <ul className="browse-lists-row__secondary-items">
            {secondaryLists.map((list, index) => {
              const listId = list.id;
              const creatorUser = Immutable.Map(usersByListId[listId]);
              const secondaryListsUuid = uuidv4();
              // + 2 because we want the primary list to be 1
              const itemPosition = index + 2;

              return (
                <li className="browse-lists-row__secondary-item" key={listId}>
                  <InView threshold={1} triggerOnce onChange={handleVisibility(itemPosition, list, secondaryListsUuid)}>
                    <ListCard
                      creator={creatorUser}
                      handleListImageClick={() => {
                        trackListImageClick({
                          impressionId: makeImpressionId(list.id, secondaryListsUuid),
                          itemPosition,
                          list
                        });
                      }}
                      handleListTitleClick={() => {
                        trackListTitleClick({
                          impressionId: makeImpressionId(list.id, secondaryListsUuid),
                          itemPosition,
                          list
                        });
                      }}
                      horizontal
                      library={library}
                      list={Immutable.Map(list)}
                      showUserBadge
                      titleTag="h3"
                      trackCreatorClick={creatorName => trackListCreatorClick({ creatorName, itemPosition, list })}
                    />
                  </InView>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

ListsRow.propTypes = {
  config: listRowConfigShape.isRequired
};

export default ListsRow;
