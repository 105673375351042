const getBrowseConfig = apiClient => {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/browse`;
  return apiClient.get(route);
};

const getBrowseCategoryConfig = (apiClient, categoryId) => {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/browse/categories${categoryId}`;
  return apiClient.get(route);
};

export { getBrowseConfig, getBrowseCategoryConfig };
