import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { ArrowBack } from '@bibliocommons/base-icons';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { childrenShape } from '@bibliocommons/bc-prop-types';
import Heading from '@bibliocommons/heading';

import { categoryShape } from 'app/shapes/browse';

import './BrowseCategoryPage.scss';

const BrowseCategoryPage = ({ category, children, backToLinkProps }) => {
  const { href, label, screenReaderLabel } = backToLinkProps;
  const { title } = category;

  return (
    <article className="cp-browse-category-page">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <header>
              <Link className="back-to-link" to={href}>
                <ArrowBack />
                <span aria-hidden>{label}</span>
                <ScreenReaderMessage>{screenReaderLabel}</ScreenReaderMessage>
              </Link>
              <Heading className="heading-primary" id="BrowseCategory" size="large" tagName="h1" variant>
                {title}
              </Heading>
            </header>
          </div>
        </div>
      </div>
      {children}
    </article>
  );
};

BrowseCategoryPage.propTypes = {
  backToLinkProps: PropTypes.shape({
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    screenReaderLabel: PropTypes.string.isRequired
  }).isRequired,
  category: categoryShape.isRequired,
  children: childrenShape.isRequired
};

export default BrowseCategoryPage;
