import Immutable from 'immutable';

import BrowseConstants from 'app/constants/BrowseConstants';

const initialState = Immutable.Map({
  categoryPage: Immutable.Map({
    bibsByCategoryId: Immutable.Map(),
    category: Immutable.Map(),
    categoryIndex: Immutable.Map()
  }),
  config: Immutable.Map(),
  isFetching: false
});

const browseReducer = (state = initialState, action) => {
  switch (action.type) {
    case BrowseConstants.FETCH_BROWSE_CATEGORY_SUCCESS: {
      return state.mergeIn(['categoryPage'], Immutable.fromJS(action.payload));
    }

    case BrowseConstants.FETCH_BROWSE_REQUEST: {
      return state.set('isFetching', true);
    }

    case BrowseConstants.FETCH_BROWSE_SUCCESS: {
      return state.merge({
        config: Immutable.fromJS(action.payload?.config?.config),
        isFetching: false
      });
    }

    case BrowseConstants.FETCH_BROWSE_FAILURE: {
      return state.set('isFetching', false);
    }

    default:
      return state;
  }
};

export default browseReducer;
