import Immutable from 'immutable';
import { combineReducers } from 'redux-immutablejs';

import createDefaultEntitiesReducer from './DefaultEntitiesReducer';

export default combineReducers(
  Immutable.Map({
    users: createDefaultEntitiesReducer('users', true),
    accounts: createDefaultEntitiesReducer('accounts', true),
    bibs: createDefaultEntitiesReducer('bibs', true),
    shelves: createDefaultEntitiesReducer('shelves', true),
    ugc: createDefaultEntitiesReducer('ugc', true),
    suggestForPurchase: createDefaultEntitiesReducer('suggestForPurchase', true),
    availabilities: createDefaultEntitiesReducer('availabilities'),
    holdings: createDefaultEntitiesReducer('holdings'),
    bibItems: createDefaultEntitiesReducer('bibItems'),
    subscriptions: createDefaultEntitiesReducer('subscriptions'),
    catalogBibs: createDefaultEntitiesReducer('catalogBibs'),
    borrowingHistory: createDefaultEntitiesReducer('borrowingHistory'),
    holds: createDefaultEntitiesReducer('holds'),
    checkouts: createDefaultEntitiesReducer('checkouts'),
    fines: createDefaultEntitiesReducer('fines'),
    interlibraryLoans: createDefaultEntitiesReducer('interlibraryLoans'),
    messages: createDefaultEntitiesReducer('messages'),
    sites: createDefaultEntitiesReducer('sites'),
    libraries: createDefaultEntitiesReducer('libraries'),
    featuredContent: createDefaultEntitiesReducer('featuredContent'),
    systemMessages: createDefaultEntitiesReducer('systemMessages'),
    features: createDefaultEntitiesReducer('features'),
    authorNotes: createDefaultEntitiesReducer('authorNotes'),
    authorSuggestions: createDefaultEntitiesReducer('authorSuggestions'),
    relatedAuthors: createDefaultEntitiesReducer('relatedAuthors'),
    externalResources: createDefaultEntitiesReducer('externalResources'),
    reviews: createDefaultEntitiesReducer('reviews'),
    recommendations: createDefaultEntitiesReducer('recommendations'),
    lists: createDefaultEntitiesReducer('lists'),
    listItems: createDefaultEntitiesReducer('listItems'),
    seriesEntries: createDefaultEntitiesReducer('seriesEntries'),
    holdableItems: createDefaultEntitiesReducer('holdableItems'),
    mediaSummaries: createDefaultEntitiesReducer('mediaSummaries'),
    excerpts: createDefaultEntitiesReducer('excerpts'),
    events: createDefaultEntitiesReducer('events'),
    eventAudiences: createDefaultEntitiesReducer('eventAudiences'),
    eventTypes: createDefaultEntitiesReducer('eventTypes'),
    eventPrograms: createDefaultEntitiesReducer('eventPrograms'),
    eventLanguages: createDefaultEntitiesReducer('eventLanguages'),
    eventRoomBooking: createDefaultEntitiesReducer('eventRoomBooking'),
    eventRegistration: createDefaultEntitiesReducer('eventRegistration'),
    locations: createDefaultEntitiesReducer('locations'),
    specialHours: createDefaultEntitiesReducer('specialHours'),
    facilityTypes: createDefaultEntitiesReducer('facilityTypes'),
    places: createDefaultEntitiesReducer('places'),
    images: createDefaultEntitiesReducer('images'),
    cards: createDefaultEntitiesReducer('cards'),
    promos: createDefaultEntitiesReducer('promos'),
    marcFields: createDefaultEntitiesReducer('marcFields'),
    eventSeries: createDefaultEntitiesReducer('eventSeries'),
    followedUsers: createDefaultEntitiesReducer('followedUsers'),
    ignoredUsers: createDefaultEntitiesReducer('ignoredUsers')
  })
);
