import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { BackLink } from '@bibliocommons/base-links';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { readSessionStorageItem } from '@bibliocommons/utils-storage';
import Context from '@bibliocommons/context';

export default function BackToLink({ href, pageId }) {
  const intl = useIntl();

  const sessionStorage = readSessionStorageItem('backToLink');
  const backToLinkHref = href || sessionStorage?.href;
  const backToLinkPageId = pageId || sessionStorage?.pageId;
  const { setBackToLinkEnabled, backToLinkEnabled, mobileApp } = useContext(Context);

  useEffect(() => {
    setBackToLinkEnabled(true);
  }, [setBackToLinkEnabled]);

  if (!mobileApp && backToLinkEnabled && backToLinkHref && backToLinkPageId) {
    const label =
      sessionStorage?.label ||
      intl.formatMessage({
        id: `back_to_link_${backToLinkPageId}`
      });

    const screenReaderLabel =
      sessionStorage?.screenReaderLabel ||
      intl.formatMessage({
        id: `sr_back_to_link_${backToLinkPageId}`
      });

    return (
      <div className="cp-back-to-link">
        <BackLink className="back-to-link" dataKey="back-to-link" href={backToLinkHref}>
          <span aria-hidden>{label}</span>
          <ScreenReaderMessage>{screenReaderLabel}</ScreenReaderMessage>
        </BackLink>
      </div>
    );
  }

  return null;
}

BackToLink.propTypes = {
  href: PropTypes.string,
  pageId: PropTypes.string
};
