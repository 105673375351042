import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Link } from '@bibliocommons/base-links';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import Heading from '@bibliocommons/heading';
import JacketCover from '@bibliocommons/shared-jacket-cover';
import ScreenReaderItemInfo from '@bibliocommons/shared-screen-reader-item-info';
import { truncate } from '@bibliocommons/utils-string';

import displayBibShape from 'app/shapes/displayBib';

import './DisplayBibSlide.scss';

const TRUNCATE_LENGTH = 20;

const DisplayBibSlide = ({ className, displayBib, size, trackClick }) => {
  const { authors, imageUrl, languageCode, metadataId, title } = displayBib;

  function renderBibInfo() {
    const truncatedTitle = truncate(title, { desiredLength: TRUNCATE_LENGTH });

    return (
      <div className="bib-info">
        <Heading className="title" lang={languageCode} tagName="div">
          <span aria-hidden>{truncatedTitle}</span>
          <ScreenReaderMessage>{title}</ScreenReaderMessage>
        </Heading>
        {authors.map((author, index) => {
          const truncatedAuthor = truncate(author, { desiredLength: TRUNCATE_LENGTH });

          return (
            <span className="author" key={author}>
              <span>
                <span aria-hidden>{truncatedAuthor}</span>
                <ScreenReaderMessage>{author}</ScreenReaderMessage>
              </span>
              {index < authors.length - 1 && ' • '}
            </span>
          );
        })}
      </div>
    );
  }

  function renderTextCover() {
    return (
      <div className="text-cover" aria-hidden>
        <div className="text-cover-inner">{renderBibInfo()}</div>
      </div>
    );
  }

  return (
    <div className={cn(['cp-display-bib-slide', className])}>
      <Link className="link" href={`/v2/record/${metadataId}`} dataKey="bib-link" handleClick={trackClick}>
        <ScreenReaderItemInfo authors={authors} title={title} />
        <JacketCover size={size} renderDefault={renderTextCover} src={imageUrl} />
        <div className="info-overlay" aria-hidden>
          {renderBibInfo()}
        </div>
      </Link>
    </div>
  );
};

DisplayBibSlide.defaultProps = {
  className: '',
  size: 'medium'
};

DisplayBibSlide.propTypes = {
  className: PropTypes.string,
  displayBib: displayBibShape.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  trackClick: PropTypes.func.isRequired
};

export default DisplayBibSlide;
