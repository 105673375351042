import Immutable from 'immutable';
import EventsConstants from '../../constants/EventsConstants';

const initialState = Immutable.fromJS({
  id: '',
  isFetching: false
});

export default function EventsDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case EventsConstants.EVENT_BY_ID_REQUEST: {
      return state.merge({
        isFetching: true
      });
    }

    case EventsConstants.EVENT_BY_ID_SUCCESS: {
      return state.merge({
        id: action.payload.event.id,
        isFetching: false
      });
    }

    case EventsConstants.EVENT_BY_ID_FAILURE: {
      return state.merge({
        isFetching: false
      });
    }

    default:
      return state;
  }
}
