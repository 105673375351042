import curry from 'lodash/curry';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { childrenShape } from '@bibliocommons/bc-prop-types';
import { TOGGLZ_FEATURES } from '@bibliocommons/constants-feature-togglz';

import { trackCatalogExplore, trackContentClick, trackNavigate } from 'app/actions/AnalyticsGa4Actions';
import { expandField } from 'app/actions/SearchActions';
import { BackToLinkManager } from 'app/components/BackToLinkManager';
import ErrorPage from 'app/components/ErrorPage';
import { DocumentHeadContainer } from 'app/components/shared/DocumentHead';
import MobileMenuOverlay from 'app/components/shared/MobileMenuOverlay';
import { METADATA_CATEGORY_TYPE, UI_COMPONENT_TYPE, UI_CONTAINER_TYPE } from 'app/constants/AnalyticsGa4Constants';
import {
  BROWSE_TITLES_PAGE_FACET_FIELD_IDS,
  FIELD_ID,
  GROUP_ID,
  NAV_ITEM_ID,
  SUB_PAGE_BASE_QUERY,
  SUB_PAGE_SLUG
} from 'app/constants/BrowseConstants';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import { MENU_ITEM_LINK_TYPE } from 'app/constants/menuConstants';
import {
  makeClearedSearchParams,
  makeFieldLabel,
  makeFilterLabel,
  makeGroupLabel,
  makeNewSearchParams,
  makeTabLinkUrl,
  mapCatalogSearchFieldsToNewFacets,
  mapQuerySearchParamsToCatalogSearchFilters
} from 'app/helpers/browse';
import { mapSearchBibToDisplayBib } from 'app/helpers/displayBib';
import useFeature from 'app/hooks/useFeature';
import { selectBibEntities } from 'app/selectors/EntitiesSelector';
import { selectShowMoreFiltersFieldId } from 'app/selectors/SearchSelector';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import { selectCurrentSiteScopes, selectDefaultScopeId } from 'app/selectors/ScopeSelector';
import BrowseTitlesPage from './BrowseTitlesPage';
import FilterableDisplayBibGrid from '../../shared/FilterableDisplayBibGrid';

const MOBILE_MENU_OVERLAY_KEY = 'browse-titles-mobile-menu-overlay';
const PATH_PREFIX = '/v2/browse/titles';

const BrowseTitlesPageContainer = ({ children }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const intl = useIntl();
  const { pathname, search } = useLocation();
  const isCoreV2NewAndOnOrderPagesEnabled = useFeature(TOGGLZ_FEATURES.CORE_V2_NEW_AND_ON_ORDER_PAGES);
  const currentLibrary = useSelector(selectCurrentLibrary);
  const isSearchScopesEnabled = currentLibrary.get('scopeFacetEnabled', false);
  const defaultSearchScopeId = useSelector(selectDefaultScopeId);
  const searchResults = useSelector(state => state.getIn(['search', 'catalogSearch', 'results']))?.toJS() ?? [];
  const searchBibs = useSelector(selectBibEntities);
  const searchScopes = useSelector(selectCurrentSiteScopes);
  const fields = useSelector(state => state.getIn(['search', 'catalogSearch', 'fields']));
  const isFetching = useSelector(state => state.getIn(['search', 'catalogSearch', 'isFetching'])) ?? false;
  const pagination = useSelector(state => state.getIn(['search', 'catalogSearch', 'pagination']));
  const showMoreFieldId = useSelector(selectShowMoreFiltersFieldId);

  if (!isCoreV2NewAndOnOrderPagesEnabled) {
    return <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />;
  }

  const displayBibs = searchResults
    .map(({ representative: metadataId }) => searchBibs.get(metadataId)?.toJS() ?? null)
    .map(mapSearchBibToDisplayBib);

  const searchParams = new URLSearchParams(search);

  const handleFilterChange = (filter, newIsApplied) => {
    const newSearchParams = makeNewSearchParams(searchParams, filter, newIsApplied);

    push(`?${newSearchParams.toString()}`);
  };

  const setShowMoreFieldId = fieldId => {
    const isOnOrderPage = pathname.endsWith(`/${SUB_PAGE_SLUG.ON_ORDER}`);
    const baseQuery = SUB_PAGE_BASE_QUERY[isOnOrderPage ? SUB_PAGE_SLUG.ON_ORDER : SUB_PAGE_SLUG.NEW];

    dispatch(
      expandField(fieldId, {
        ...baseQuery,
        ...mapQuerySearchParamsToCatalogSearchFilters(searchParams, {
          fieldIds: BROWSE_TITLES_PAGE_FACET_FIELD_IDS
        })
      })
    );
  };

  const unapplyAllFilters = () => {
    const newSearchParams = makeClearedSearchParams({
      fieldIds: BROWSE_TITLES_PAGE_FACET_FIELD_IDS,
      oldSearchParams: searchParams
    });

    push(`?${newSearchParams.toString()}`);
  };

  const defaultFilterIdByFieldId = {
    [FIELD_ID.SCOPE]: defaultSearchScopeId
  };

  const facetFields = mapCatalogSearchFieldsToNewFacets(fields.toJS(), {
    defaultFilterIdByFieldId,
    formatFieldLabel: curry(makeFieldLabel)(intl),
    formatFilterLabel: curry(makeFilterLabel)(intl),
    formatGroupLabel: curry(makeGroupLabel)(intl),
    formatShowMoreOverlayTitle: fieldId => {
      return intl.formatMessage({ defaultMessage: `All ${fieldId}`, id: `all_${fieldId.toLowerCase()}` });
    },
    orderedFieldIds: BROWSE_TITLES_PAGE_FACET_FIELD_IDS,
    orderedGroupIdsByFieldId: {
      [FIELD_ID.FORMAT]: [
        GROUP_ID.BOOKS,
        GROUP_ID.AUDIOBOOKS_SPOKEN_WORD,
        GROUP_ID.MOVIES_TV_VIDEO,
        GROUP_ID.MUSIC_SOUND,
        GROUP_ID.GAMES_INTERACTIVE_MEDIA,
        GROUP_ID.JOURNALS_MAGAZINES,
        GROUP_ID.ACCESSIBLE_FORMATS,
        GROUP_ID.OTHER
      ]
    },
    searchParams,
    searchScopes: isSearchScopesEnabled ? searchScopes?.toJS() : [],
    showMoreFieldId
  });

  const appliedFiltersAccessibilityHeading = intl.formatMessage({ id: 'browse_active_filters' });

  const facetsHeading = intl.formatMessage({
    defaultMessage: 'Browse filters',
    id: 'browse_facets'
  });

  const tabLinks = [
    {
      id: SUB_PAGE_SLUG.NEW,
      isActive: pathname.endsWith(`/${SUB_PAGE_SLUG.NEW}`),
      text: intl.formatMessage({ defaultMessage: 'New titles', id: 'browse.nav.new' }),
      to: makeTabLinkUrl({ path: `${PATH_PREFIX}/${SUB_PAGE_SLUG.NEW}`, searchParams })
    },
    {
      id: SUB_PAGE_SLUG.ON_ORDER,
      isActive: pathname.endsWith(`/${SUB_PAGE_SLUG.ON_ORDER}`),
      text: intl.formatMessage({ defaultMessage: 'On order titles', id: 'browse.nav.on_order' }),
      to: makeTabLinkUrl({ path: `${PATH_PREFIX}/${SUB_PAGE_SLUG.ON_ORDER}`, searchParams })
    }
  ];

  const NAV_ITEM_BY_ID = {
    [NAV_ITEM_ID.AWARDS]: {
      id: NAV_ITEM_ID.AWARDS,
      isActive: false,
      text: intl.formatMessage({ defaultMessage: 'Award winners', id: 'browse.nav.awards' }),
      url: '/explore/awards'
    },
    [NAV_ITEM_ID.BEST_SELLERS]: {
      id: NAV_ITEM_ID.BEST_SELLERS,
      isActive: false,
      text: intl.formatMessage({ defaultMessage: 'Best sellers', id: 'browse.nav.best_sellers' }),
      url: '/explore/bestsellers'
    },
    [NAV_ITEM_ID.LANGUAGES]: {
      id: NAV_ITEM_ID.LANGUAGES,
      isActive: false,
      text: intl.formatMessage({ defaultMessage: 'World languages', id: 'browse.nav.languages' }),
      url: '/explore/featured_lists/languages'
    },
    [NAV_ITEM_ID.NEW_AND_ON_ORDER]: {
      id: NAV_ITEM_ID.NEW_AND_ON_ORDER,
      isActive: true,
      text: intl.formatMessage({ defaultMessage: 'New and on order', id: 'browse.nav.new_and_on_order' }),
      url: `${PATH_PREFIX}/${SUB_PAGE_SLUG.NEW}`
    },
    [NAV_ITEM_ID.STAFF_PICKS]: {
      id: NAV_ITEM_ID.STAFF_PICKS,
      isActive: false,
      text: intl.formatMessage({ defaultMessage: 'Staff picks', id: 'browse.nav.staff_picks' }),
      url: '/explore/featured_lists/staff_picks'
    },
    [NAV_ITEM_ID.TEACHER_PICKS]: {
      id: NAV_ITEM_ID.TEACHER_PICKS,
      isActive: false,
      text: intl.formatMessage({ defaultMessage: 'Teacher picks', id: 'browse.nav.teacher_picks' }),
      url: '/explore/featured_lists/teacher_picks'
    }
  };
  const navSettings = currentLibrary.getIn(['browse', 'nav'])?.toJS() ?? {};
  const navItems = [
    NAV_ITEM_ID.NEW_AND_ON_ORDER,
    NAV_ITEM_ID.AWARDS,
    NAV_ITEM_ID.BEST_SELLERS,
    NAV_ITEM_ID.STAFF_PICKS,
    NAV_ITEM_ID.LANGUAGES,
    NAV_ITEM_ID.TEACHER_PICKS
  ]
    .filter(navItemId => navItemId === NAV_ITEM_ID.NEW_AND_ON_ORDER || navSettings[`has${navItemId}`])
    .map(navItemId => NAV_ITEM_BY_ID[navItemId]);

  const mobileMenuItems = navItems.map(navItem => {
    const { isActive, text: label, url } = navItem;

    return {
      isActive,
      isExpanded: false,
      label,
      linkType: url.startsWith(PATH_PREFIX) ? MENU_ITEM_LINK_TYPE.HISTORY : MENU_ITEM_LINK_TYPE.STANDARD,
      url
    };
  });

  const mobileMenuOverlayTitle = intl.formatMessage({ defaultMessage: 'Browse', id: 'browse' });
  const pageTitle = intl.formatMessage({ defaultMessage: 'New and on order', id: 'browse.nav.new_and_on_order' });

  const renderMobileMenuOverlay = () => {
    return (
      <MobileMenuOverlay
        menuItems={mobileMenuItems}
        overlayKey={MOBILE_MENU_OVERLAY_KEY}
        title={mobileMenuOverlayTitle}
        triggerLabel={pageTitle}
      />
    );
  };

  const trackAuthorClick = ({ metadataId, author, itemPosition }) => {
    const { count: numTotalItems, page } = pagination?.toJS() ?? {};

    dispatch(
      trackCatalogExplore({
        bibId: metadataId,
        metadata: {
          metadata_category_type: METADATA_CATEGORY_TYPE.BIBLIOGRAPHIC_DETAILS_CREATORS,
          metadata_value: author
        },
        ui: {
          ui_component_label: author,
          ui_component_type: UI_COMPONENT_TYPE.LINK_TEXT,
          ui_container_type: UI_CONTAINER_TYPE.ITEM,
          ui_content_page: page,
          ui_content_position: itemPosition,
          ui_content_total_count: numTotalItems
        }
      })
    );
  };

  const trackBibLinkClick = (metadataId, title, isImage, itemPosition) => {
    const { count: numTotalItems, page } = pagination?.toJS() ?? {};

    dispatch(
      trackContentClick({
        bibId: metadataId,
        metadata: {
          metadata_category_type: METADATA_CATEGORY_TYPE.BIBLIOGRAPHIC_DETAILS_TITLE,
          metadata_value: title
        },
        ui: {
          ...(isImage ? {} : { ui_component_label: title }),
          ui_component_type: isImage ? UI_COMPONENT_TYPE.LINK_IMAGE : UI_COMPONENT_TYPE.LINK_TEXT,
          ui_container_type: UI_CONTAINER_TYPE.ITEM,
          ui_content_page: page,
          ui_content_position: itemPosition,
          ui_content_total_count: numTotalItems
        }
      })
    );
  };

  const renderFilterableDisplayBibGrid = () => {
    return (
      <FilterableDisplayBibGrid
        appliedFiltersAccessibilityHeading={appliedFiltersAccessibilityHeading}
        displayBibs={displayBibs}
        facetFields={facetFields}
        facetsHeading={facetsHeading}
        handleFilterChange={handleFilterChange}
        isFetching={isFetching}
        pagination={pagination}
        setShowMoreFieldId={setShowMoreFieldId}
        trackAuthorClick={trackAuthorClick}
        trackBibLinkClick={trackBibLinkClick}
        unapplyAllFilters={unapplyAllFilters}
      />
    );
  };

  const trackNavLinkClick = (url, text) => {
    dispatch(
      trackNavigate({
        metadata: {
          metadata_category_type: METADATA_CATEGORY_TYPE.NAVIGATION,
          metadata_value: url
        },
        ui: {
          ui_component_label: text,
          ui_component_type: UI_COMPONENT_TYPE.LINK_TEXT,
          ui_container_type: UI_CONTAINER_TYPE.BROWSE_TITLES_NAV
        }
      })
    );
  };

  return (
    <>
      <DocumentHeadContainer pageTitle={pageTitle} />
      <BackToLinkManager pageId="recent_arrivals" />
      <BrowseTitlesPage
        navItems={navItems}
        pageTitle={pageTitle}
        renderFilterableDisplayBibGrid={renderFilterableDisplayBibGrid}
        renderMobileMenuOverlay={renderMobileMenuOverlay}
        tabLinks={tabLinks}
        trackNavLinkClick={trackNavLinkClick}
      >
        {children}
      </BrowseTitlesPage>
    </>
  );
};

BrowseTitlesPageContainer.propTypes = {
  children: childrenShape.isRequired
};

export default BrowseTitlesPageContainer;
