import React from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import connectRoute from 'app/routes/connectRoute';
import ErrorPage from 'app/components/ErrorPage';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import EventsActions from 'app/actions/EventsActions';

const EventsDetailsPageContainer = loadable(() =>
  import('app/components/events/EventsDetailsPage/EventsDetailsPageContainer')
);

export function EventsDetailsPageRoute() {
  const currentLibrary = useSelector(selectCurrentLibrary);

  if (!currentLibrary.getIn(['events', 'enabled'])) {
    return <ErrorPage httpStatusCode={HTTP_STATUS_CODE.FORBIDDEN} pageMessageKey="events" />;
  }

  return <EventsDetailsPageContainer />;
}

function getRouteData({ state, dispatch, params }) {
  const currentLibrary = selectCurrentLibrary(state);
  if (!currentLibrary.getIn(['events', 'enabled'])) return [];

  return [dispatch(EventsActions.getEventById(params.eventId))];
}

export default connectRoute(EventsDetailsPageRoute, {
  getRouteData,
  debounceOptions: {
    wait: 100
  }
});
