import mapValues from 'lodash/mapValues';
import { map } from 'rxjs/operators';

import { trackNavigate } from 'app/actions/AnalyticsGa4Actions';
import { getBrowseCategoryConfig, getBrowseConfig } from 'app/api/BrowseApi';
import { METADATA_CATEGORY_TYPE, UI_COMPONENT_TYPE, UI_CONTAINER_TYPE } from 'app/constants/AnalyticsGa4Constants';
import BrowseConstants from 'app/constants/BrowseConstants';
import { mapCatalogRecordBibToDisplayBib } from 'app/helpers/displayBib';

const fetchBrowseConfig = () => {
  return {
    types: [
      BrowseConstants.FETCH_BROWSE_REQUEST,
      BrowseConstants.FETCH_BROWSE_SUCCESS,
      BrowseConstants.FETCH_BROWSE_FAILURE
    ],
    apiRequest: (_state, apiClient) => getBrowseConfig(apiClient)
  };
};

const fetchCategoryConfig = ({ categoryId, postSuccess }) => {
  return {
    apiRequest: (_state, apiClient) => {
      return getBrowseCategoryConfig(apiClient, categoryId).pipe(
        map(res => {
          const { body } = res;
          const { bibsByCategoryId = {} } = body;

          const displayBibsByCategoryId = mapValues(bibsByCategoryId, bibs =>
            bibs.map(mapCatalogRecordBibToDisplayBib)
          );

          return { body: { ...res.body, bibsByCategoryId: displayBibsByCategoryId } };
        })
      );
    },
    postSuccess,
    types: [
      BrowseConstants.FETCH_BROWSE_CATEGORY_REQUEST,
      BrowseConstants.FETCH_BROWSE_CATEGORY_SUCCESS,
      BrowseConstants.FETCH_BROWSE_CATEGORY_FAILURE
    ]
  };
};

const trackNavLinkClick = ({ itemPosition, linkText, linkUrl, rowPosition, rowTitle }) => {
  return trackNavigate({
    metadata: {
      metadata_category_type: METADATA_CATEGORY_TYPE.NAVIGATION,
      metadata_value: linkUrl
    },
    ui: {
      ui_component_label: linkText,
      ui_component_type: UI_COMPONENT_TYPE.LINK_TEXT,
      ui_container_position: rowPosition,
      ui_container_title: rowTitle,
      ui_container_type: UI_CONTAINER_TYPE.BROWSE_ROW_LIST,
      ui_content_position: itemPosition
    }
  });
};

export { fetchBrowseConfig, fetchCategoryConfig, trackNavLinkClick };
