import { useEffect, useContext } from 'react';
import { useRouter } from '@bibliocommons/utils-routing';
import PropTypes from 'prop-types';
import { writeSessionStorageItem } from '@bibliocommons/utils-storage';
import Context from '@bibliocommons/context';

export default function BackToLinkManager({ href, label, pageId, screenReaderLabel }) {
  const { setBackToLinkEnabled, backToLinkEnabled } = useContext(Context);
  const { location } = useRouter();
  const backToLinkHref = href || location.pathname + location.search;

  useEffect(() => {
    setBackToLinkEnabled(true);
  }, [setBackToLinkEnabled]);

  if (backToLinkEnabled) {
    writeSessionStorageItem('backToLink', { href: backToLinkHref, label, pageId, screenReaderLabel });
  }

  return null;
}

BackToLinkManager.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
  pageId: PropTypes.string.isRequired,
  screenReaderLabel: PropTypes.string
};
