import uniqueId from 'lodash/uniqueId';
import React from 'react';
import { useDispatch } from 'react-redux';

import { trackNavLinkClick as createTrackNavLinkClickAction } from 'app/actions/BrowseActions';
import { BROWSE_ROW_TYPE } from 'app/constants/BrowseConstants';
import { mapCategoriesToNavRowLinks } from 'app/helpers/browse';
import { categoryShape, immutableCategoryIndexShape } from 'app/shapes/browse';
import displayBibShape from 'app/shapes/displayBib';
import PropTypes from 'prop-types';
import Rows from '../shared/rows/Rows';

const BrowseCategoryNodePageContainer = ({ bibsByCategoryId, category, categoryIndex }) => {
  const dispatch = useDispatch();

  const { categoryId, subCategoryIds } = category;

  // TODO: confirm screen-reader title
  const screenReaderNavTitle = `Browse ${category.title} by category`;

  const navRowLinks = mapCategoriesToNavRowLinks(
    subCategoryIds.map(nextCategoryId => categoryIndex.get(nextCategoryId)?.toJS()).filter(Boolean)
  );

  const displayBibRowConfigs = [categoryId].concat(subCategoryIds).map(nextCategoryId => {
    const nextCategory = categoryIndex.get(nextCategoryId)?.toJS() ?? {};
    const { title = '' } = nextCategory;
    const titleUrl = `/v2/browse${nextCategoryId}`;

    return {
      config: {
        displayBibs: bibsByCategoryId[nextCategoryId] ?? [],
        hasHistoryRoutingForTitles: true,
        title,
        titleUrl,
        trackBibClick: () => null,
        trackTitleClick: () => null
      },
      hasAlternativeStyle: false,
      rowConfigId: uniqueId(BROWSE_ROW_TYPE.BIB),
      rowPosition: 1,
      type: BROWSE_ROW_TYPE.BIB
    };
  });

  const trackNavLinkClick = ({ itemPosition, linkText, linkUrl }) => {
    dispatch(
      createTrackNavLinkClickAction({
        itemPosition,
        linkText,
        linkUrl,
        rowPosition: 1,
        rowTitle: screenReaderNavTitle
      })
    );
  };

  const rowConfigs = [
    {
      config: {
        links: navRowLinks,
        title: screenReaderNavTitle,
        trackClick: trackNavLinkClick
      },
      hasAlternativeStyle: false,
      rowConfigId: uniqueId(BROWSE_ROW_TYPE.NAV),
      rowPosition: 1,
      type: BROWSE_ROW_TYPE.NAV
    }
  ].concat(displayBibRowConfigs);

  return <Rows rowConfigs={rowConfigs} />;
};

BrowseCategoryNodePageContainer.propTypes = {
  bibsByCategoryId: PropTypes.objectOf(PropTypes.arrayOf(displayBibShape)).isRequired,
  categoryIndex: immutableCategoryIndexShape.isRequired,
  category: categoryShape.isRequired
};

export default BrowseCategoryNodePageContainer;
