import React from 'react';

import Svg from '@bibliocommons/svg';

import { WINDOW_WIDTH_MIN_PX } from 'app/constants/window';
import useWindowWidth from 'app/hooks/useWindowWidth';

import './LoadingStencil.scss';

const LoadingStencil = () => {
  const windowWidthPx = useWindowWidth();

  const numRects = 6;
  const rectHeight = 24;
  const verticalMargin = 8;
  const totalHeightPx = numRects * (rectHeight + verticalMargin);

  const numColumns = windowWidthPx >= WINDOW_WIDTH_MIN_PX.SMALL ? 2 : 1;

  return (
    <div className="cp-browse-show-more-loading-stencil">
      <div className="grid">
        {new Array(numColumns)
          .fill(null)
          .map((_, colIndex) => colIndex + 1)
          .map(colId => {
            return (
              <Svg
                className="table-stencil__desktop"
                height={`${totalHeightPx}px`}
                key={colId}
                preserveAspectRatio="none"
                viewBox={`0 0 100 ${totalHeightPx}`}
                width="100%"
              >
                {new Array(numRects).fill(null).map((_, rowIndex) => {
                  const yPosition = rowIndex * (rectHeight + verticalMargin);
                  return (
                    <rect
                      height={rectHeight}
                      key={yPosition}
                      y={yPosition}
                      width="100%"
                      xmlns="http://www.w3.org/2000/svg"
                    />
                  );
                })}
              </Svg>
            );
          })}
      </div>
    </div>
  );
};

export default LoadingStencil;
