import { threeLetterToTwoLetterLangCodes } from '@bibliocommons/constants-languages';

import {
  AUDIENCE,
  CATALOG_RECORD_BIB_IMAGE_INFO_TYPE,
  CATALOG_RECORD_BIB_TITLE_TYPE,
  CONTENT_TYPE,
  FORMAT_ID
} from 'app/constants/displayBib';

export const getRoundedRatingOutOf5 = ratingPercentage => {
  return Math.round((ratingPercentage / 20) * 10) / 10;
};

// Catalog Record Bibs come from Bibliographics' /catalog/bibs endpoint
export const mapCatalogBibToDisplayBib = catalogBib => {
  const { brief, fields, metadataId } = catalogBib;

  const {
    coverImage,
    creators,
    description,
    format: formatId,
    otherScriptSubTitle,
    otherScriptTitle,
    primaryLanguage,
    subTitle: defaultSubTitle,
    title: defaultTitle
  } = brief;

  const authors = (creators || []).map(creator => creator.fullName);
  const imageUrl = coverImage?.large || coverImage?.medium || coverImage?.small || null;
  const languageCode = threeLetterToTwoLetterLangCodes?.[primaryLanguage] || threeLetterToTwoLetterLangCodes.eng;
  const publicationStatement =
    fields?.find(field => field.category === 'DETAILS')?.items?.find(fieldItem => fieldItem.fieldName === 'PUBLICATION')
      ?.fieldValues?.[0]?.primary?.values?.[0] ?? '';
  const subtitle = otherScriptSubTitle || defaultSubTitle || '';
  const title = otherScriptTitle || defaultTitle || '';

  return {
    audience: AUDIENCE.UNKNOWN, // catalog bib does not have an audience
    authors,
    callNumber: null, // catalog bib does not have a callNumber
    contentType: CONTENT_TYPE.UNDETERMINED, // catalog bib does not have a contentType
    description,
    formatId,
    fulfillmentProvider: null, // catalog bib does not have a fulfillmentProvider
    groupKey: '', // catalog bib does not have a groupKey
    imageUrl,
    languageCode,
    metadataId,
    publicationStatement,
    ratingCount: 0, // rating values are not present on the catalog bib
    ratingPercentage: 0,
    subtitle,
    title
  };
};

const getCatalogRecordBibDescription = catalogRecordBib => {
  const PROVIDER_ID = {
    CONTENT_CAFE: 'content cafe',
    HOOPLA: 'hoopla',
    MARC_21: 'marc 21',
    SYNDETICS: 'syndetics'
  };

  const summaryByProviderId = (catalogRecordBib.summaries || []).reduce((acc, summary) => {
    const providerId = (summary?.provider?.name ?? '').toLowerCase();
    if (!providerId) {
      return acc;
    }

    if (!Object.values(PROVIDER_ID).includes(providerId)) {
      // eslint-disable-next-line no-console
      console.warn(`Unknown Bib Summary Provider ID: ${providerId}`);
      return acc;
    }

    acc[providerId] = summary?.contents ?? '';

    return acc;
  }, {});

  return (
    summaryByProviderId[PROVIDER_ID.SYNDETICS] ||
    summaryByProviderId[PROVIDER_ID.CONTENT_CAFE] ||
    summaryByProviderId[PROVIDER_ID.MARC_21] ||
    null
  );
};

// Catalog Record Bibs come from Bibliographics' /catalog/records endpoint
export const mapCatalogRecordBibToDisplayBib = (catalogRecordBib, { callNumber, fulfillmentPolicy }) => {
  if (!Object.values(FORMAT_ID).includes(catalogRecordBib.format)) {
    // eslint-disable-next-line no-console
    console.warn(`${catalogRecordBib.format} is not a known bib format id`);
  }

  const authors = (catalogRecordBib.creators ?? []).map(creator => {
    return creator.creator?.name.otherScriptFullNames?.[0]?.content || creator?.creator?.name?.fullName || '';
  });

  const coverImageInfo =
    (catalogRecordBib.coverImageInfo ?? []).find(
      imageInfo => imageInfo.imageType === CATALOG_RECORD_BIB_IMAGE_INFO_TYPE.LOCAL
    ) ??
    (catalogRecordBib.coverImageInfo ?? []).find(
      imageInfo => imageInfo.imageType === CATALOG_RECORD_BIB_IMAGE_INFO_TYPE.NONLOCAL
    ) ??
    null;
  const imageUrl = coverImageInfo?.largeImageLink ?? null;

  const titleRecord =
    (catalogRecordBib.titles ?? []).find(record => record.type === CATALOG_RECORD_BIB_TITLE_TYPE.MANIFESTATION) ??
    catalogRecordBib.titles?.[0];
  const subtitle = titleRecord?.subTitleOtherScripts?.[0]?.content || titleRecord?.subTitle || '';
  const title = titleRecord?.mainTitleOtherScripts?.[0]?.content || titleRecord?.mainTitle || '';

  return {
    audience: catalogRecordBib.audiences?.[0] ?? AUDIENCE.UNKNOWN,
    authors,
    callNumber,
    contentType: catalogRecordBib.contentType ?? CONTENT_TYPE.UNDETERMINED,
    description: getCatalogRecordBibDescription(catalogRecordBib),
    formatId: catalogRecordBib.format,
    fulfillmentProvider: fulfillmentPolicy?.provider ?? null,
    groupKey: catalogRecordBib.legacyGrouping?.groupKey?.text ?? '',
    imageUrl,
    languageCode:
      threeLetterToTwoLetterLangCodes?.[catalogRecordBib.languages?.[0]] || threeLetterToTwoLetterLangCodes.eng,
    metadataId: catalogRecordBib.metadataId,
    publicationStatement: catalogRecordBib.publicationInfo?.[0]?.statement || '',
    ratingCount: 0, // rating values are not present on the catalog record bib
    ratingPercentage: 0,
    subtitle,
    title
  };
};

export const mapSearchBibToDisplayBib = searchBib => {
  if (!searchBib) {
    return null;
  }

  const { briefInfo, id: metadataId, policy: fulfillmentPolicy } = searchBib;
  const {
    audiences,
    authors,
    callNumber,
    contentType,
    description,
    format: formatId,
    groupKey,
    jacket,
    multiscriptAuthor,
    multiscriptTitle,
    primaryLanguage,
    rating,
    subtitle,
    title
  } = briefInfo;
  const { provider: fulfillmentProvider } = fulfillmentPolicy;

  const imageUrl = jacket.large || jacket.medium || jacket.small || null;

  return {
    audience: audiences?.[0] ?? AUDIENCE.UNKNOWN,
    authors: multiscriptAuthor ? [multiscriptAuthor] : authors,
    callNumber,
    contentType: contentType ?? CONTENT_TYPE.UNDETERMINED,
    description,
    formatId,
    fulfillmentProvider,
    groupKey,
    imageUrl,
    languageCode: threeLetterToTwoLetterLangCodes?.[primaryLanguage] || threeLetterToTwoLetterLangCodes.eng,
    metadataId,
    publicationStatement: null,
    ratingCount: rating?.totalCount ?? 0,
    ratingPercentage: rating?.averageRating ?? 0,
    subtitle,
    title: multiscriptTitle || title
  };
};
