import React from 'react';

import { fetchCategoryConfig } from 'app/actions/BrowseActions';
import { performCatalogSearch } from 'app/actions/SearchActions';
import BrowseCategoryPage from 'app/components/browse/category';
import { BROWSE_CATEGORY_PAGE_FACET_FIELD_IDS } from 'app/constants/BrowseConstants';
import { mapQuerySearchParamsToCatalogSearchFilters } from 'app/helpers/browse';
import connectRoute from 'app/routes/connectRoute';

const BrowseCategoryPageRoute = () => <BrowseCategoryPage />;

const createPerformCatalogSearchForCategoryAction = search => {
  return state => {
    const category = state.getIn(['browse', 'categoryPage', 'category'])?.toJS();
    const isLeaf = state.getIn(['browse', 'categoryPage', 'isLeaf']);

    if (!isLeaf) {
      return null;
    }

    const baseSearchQuery = {
      ...category?.queryParams,
      limit: 18,
      view: 'grouped'
    };

    const searchQueryParams = new URLSearchParams(search);
    const catalogSearchFilters = mapQuerySearchParamsToCatalogSearchFilters(searchQueryParams, {
      fieldIds: BROWSE_CATEGORY_PAGE_FACET_FIELD_IDS
    });

    return performCatalogSearch(
      { ...baseSearchQuery, ...catalogSearchFilters },
      { isPersistShowMoreFiltersFieldId: true }
    );
  };
};

const getRouteData = ({ dispatch, location, params, state }) => {
  const [categoryId] = params ?? [];
  const serializedCategoryId = '/'.concat(categoryId || '').replace(/\/+$/, '');
  const loadedCategoryId = state.getIn(['browse', 'categoryPage', 'category', 'categoryId']);
  if (loadedCategoryId === serializedCategoryId) {
    return [dispatch(createPerformCatalogSearchForCategoryAction(location.search)(state))];
  }

  return [
    dispatch(
      fetchCategoryConfig({
        postSuccess: createPerformCatalogSearchForCategoryAction(location.search),
        categoryId: serializedCategoryId
      })
    )
  ];
};

export default connectRoute(BrowseCategoryPageRoute, { getRouteData });
