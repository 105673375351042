import Immutable from 'immutable';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { BackToLinkManager } from 'app/components/BackToLinkManager';
import ErrorPage from 'app/components/ErrorPage';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import { selectCategoryPage } from 'app/selectors/BrowseSelector';
import BrowseCategoryPage from './BrowseCategoryPage';
import BrowseCategoryLeafPageContainer from './BrowseCategoryLeafPageContainer';
import BrowseCategoryNodePageContainer from './BrowseCategoryNodePageContainer';

const BrowseCategoryPageContainer = () => {
  const intl = useIntl();

  const pageState = useSelector(selectCategoryPage);

  const bibsByCategoryId = pageState.get('bibsByCategoryId')?.toJS() ?? {};
  const category = pageState.get('category')?.toJS();
  const categoryIndex = pageState.get('categoryIndex', Immutable.Map());

  if (!category) {
    return <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />;
  }

  const isCategoryLeaf = category.subCategoryIds.length === 0;
  const parentCategory = categoryIndex.get(category.parentCategoryId)?.toJS() ?? null;
  const backToPageTitle = parentCategory?.title ?? intl.formatMessage({ id: 'browse' });
  const backToPageScreenReaderLabel = intl.formatMessage(
    {
      defaultMessage: 'Back to {pageTitle}',
      id: 'sr_back_to_page_title'
    },
    {
      pageTitle: backToPageTitle
    }
  );

  const backToLinkProps = {
    href: `/v2/browse${parentCategory?.categoryId ?? ''}`,
    label: backToPageTitle,
    screenReaderLabel: backToPageScreenReaderLabel
  };

  return (
    <>
      <BackToLinkManager
        label={backToPageTitle}
        pageId="BrowseCategoryPage"
        screenReaderLabel={backToPageScreenReaderLabel}
      />
      <BrowseCategoryPage backToLinkProps={backToLinkProps} category={category}>
        {isCategoryLeaf ? (
          <BrowseCategoryLeafPageContainer category={category} />
        ) : (
          <BrowseCategoryNodePageContainer
            bibsByCategoryId={bibsByCategoryId}
            category={category}
            categoryIndex={categoryIndex}
          />
        )}
      </BrowseCategoryPage>
    </>
  );
};

export default BrowseCategoryPageContainer;
