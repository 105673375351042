import flatMap from 'lodash/flatMap';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import uniqueId from 'lodash/uniqueId';

import { FILTER_PREFIX, FILTER_SEPARATOR } from 'app/constants/BibConstants';
import {
  BROWSE_ROW_TYPE,
  FIELD_ID,
  FILTERS_TYPE,
  FILTERS_TYPE_BY_FIELD_ID,
  GROUP_ID,
  MAX_NUM_SHOWN_FILTERS_IN_CONDENSED_VIEW
} from 'app/constants/BrowseConstants';
import { mapCatalogBibToDisplayBib } from 'app/helpers/displayBib';

const PAGE_KEY = 'page';
const SCOPE_FIELD_QUERY_PARM_KEY = 'searchScope';

const serializeFieldIdForQuerystring = fieldId => {
  if (fieldId === FIELD_ID.SCOPE) {
    return SCOPE_FIELD_QUERY_PARM_KEY;
  }

  return fieldId.toLowerCase();
};

const makeFieldLabel = (intl, { fieldId }) => {
  return intl.formatMessage({ id: `${fieldId}.field` });
};

const makeFilterLabel = (intl, { fieldId, filterId, filterLabel, numResults }) => {
  let labelBeforeNumResults = '';

  if (fieldId === FIELD_ID.AUTHOR) {
    labelBeforeNumResults = filterId;
  } else if (fieldId === FIELD_ID.SCOPE) {
    labelBeforeNumResults = filterLabel;
  } else {
    labelBeforeNumResults = intl.formatMessage({
      defaultMessage: filterId,
      id: `${fieldId}.${filterId.toLowerCase()}`
    });
  }

  const isShowNumResults = Number.isInteger(numResults);

  return `${labelBeforeNumResults}${isShowNumResults ? ` (${numResults})` : ''}`;
};

const makeGroupLabel = (intl, { fieldId, groupId }) => {
  if (fieldId === FIELD_ID.FORMAT) {
    return intl.formatMessage({ id: `SUPERFORMAT.${groupId.toLowerCase()}` });
  }

  return groupId;
};

const makeClearedSearchParams = ({ fieldIds, oldSearchParams }) => {
  const newSearchParams = new URLSearchParams(oldSearchParams.toString());

  fieldIds.forEach(fieldId => {
    const serializedFieldId = serializeFieldIdForQuerystring(fieldId);

    if (fieldId !== FIELD_ID.SCOPE) {
      newSearchParams.delete(serializedFieldId);
    }
  });

  newSearchParams.delete(PAGE_KEY);

  return newSearchParams;
};

const makeNewSearchParams = (oldSearchParams, filter, newIsApplied) => {
  const { filterId, fieldId, isDefault, isExclusive } = filter;

  const newSearchParams = new URLSearchParams(oldSearchParams.toString());

  const serializedFieldId = serializeFieldIdForQuerystring(fieldId);

  if (newIsApplied && isExclusive) {
    if (isDefault) {
      newSearchParams.delete(serializedFieldId);
    } else {
      newSearchParams.set(serializedFieldId, filterId);
    }
  } else if (newIsApplied) {
    newSearchParams.append(serializedFieldId, filterId);
  } else {
    newSearchParams.delete(serializedFieldId, filterId);
  }

  // whenever a filter changes, the pagination will need to reset to page 1
  newSearchParams.delete(PAGE_KEY);

  return newSearchParams;
};

const makeTabLinkUrl = ({ path, searchParams }) => {
  const destinationSearchParams = new URLSearchParams();
  const serializedScopeFieldKey = serializeFieldIdForQuerystring(FIELD_ID.SCOPE);
  const searchScope = searchParams.get(serializedScopeFieldKey);

  if (searchScope) {
    destinationSearchParams.append(serializedScopeFieldKey, searchScope);
  }

  const querystring = destinationSearchParams.toString();

  return path.concat(querystring.length ? `?${querystring}` : '');
};

const getNumAppliedFilters = (fieldId, groups) => {
  if (fieldId === FIELD_ID.SCOPE) {
    return 0;
  }

  const uniqueFilters = uniqBy(
    flatMap(groups, group => group.filters),
    'filterId'
  );

  return uniqueFilters.filter(filter => filter.isApplied).length;
};

const getUniqueAppliedFilters = fields => {
  return uniqBy(
    flatMap(fields, field => {
      if (field.fieldId === FIELD_ID.SCOPE) {
        return [];
      }

      return flatMap(field.groups, group => {
        return group.filters.filter(filter => filter.isApplied);
      });
    }),
    'filterId'
  );
};

const mapCategoriesToNavRowLinks = categories => {
  return categories.map(category => {
    const { categoryId, title } = category;

    return {
      isRouterLink: true,
      title,
      url: `/v2/browse${categoryId}`
    };
  });
};

const mapV1SearchUrlToQueryParams = v1SearchUrl => {
  let searchParams = {};

  try {
    searchParams = new URL(v1SearchUrl).searchParams;
  } catch {
    searchParams = new URLSearchParams(v1SearchUrl);
  }

  const query = searchParams.get('q') ?? searchParams.get('custom_query');
  const searchType = searchParams.get('search_category');
  const sort = searchParams.get('sort[field]')?.toLowerCase() ?? null;

  return {
    query,
    searchType,
    sort
  };
};

const mapV2SearchUrlToQueryParams = v2SearchUrl => {
  const url = new URL(v2SearchUrl);
  const searchParams = url.searchParams;

  const query = searchParams.get('query');
  const searchType = searchParams.get('searchType');
  const sort = searchParams.get('sort');

  return {
    query,
    searchType,
    sort
  };
};

const isV2SearchUrl = url => {
  try {
    return new URL(url).pathname === '/v2/search';
  } catch {
    return Boolean(new URLSearchParams(url).get('searchType'));
  }
};

const mapSearchUrlToQueryParams = searchUrl => {
  return isV2SearchUrl(searchUrl) ? mapV2SearchUrlToQueryParams(searchUrl) : mapV1SearchUrlToQueryParams(searchUrl);
};

const makeCategoryId = (categoryTitle, parentCategoryId) =>
  `${parentCategoryId ?? ''}/${categoryTitle.toLowerCase().replaceAll(' ', '-')}`;

const makeCategoryIndex = (rawCategories, parentCategoryId = null) => {
  return rawCategories.reduce((acc, rawCategory) => {
    const { searchUrl, subCategories, title } = rawCategory;

    const categoryId = makeCategoryId(title, parentCategoryId);
    const subCategoryIds = subCategories.map(subCategory => makeCategoryId(subCategory.title, categoryId));

    acc[categoryId] = {
      categoryId,
      parentCategoryId,
      queryParams: mapSearchUrlToQueryParams(searchUrl),
      subCategoryIds,
      title
    };

    if (subCategories.length) {
      /* eslint-disable-next-line no-param-reassign */
      acc = { ...acc, ...makeCategoryIndex(subCategories, categoryId) };
    }

    return acc;
  }, {});
};

const isAppliedFilter = ({ fieldId, filterId, isDefault, searchParams }) => {
  const serializedFieldId = serializeFieldIdForQuerystring(fieldId);
  const allAppliedFilterIdsInField = searchParams.getAll(serializedFieldId);
  if (allAppliedFilterIdsInField.length === 0) {
    return isDefault;
  }

  return allAppliedFilterIdsInField.indexOf(filterId) > -1;
};

const isDefaultFilter = ({ defaultFilterId, filterId, filtersType, index }) => {
  // only RADIO_BUTTONS display types have a default
  if (filtersType !== FILTERS_TYPE.RADIO_BUTTONS) {
    return false;
  }

  // if there is no default set for the field, the first filter is the default
  return defaultFilterId ? defaultFilterId === filterId : index === 0;
};

const mapBrowseConfigElementsToRowConfigs = (
  browseConfigElements,
  {
    catalogBibsByMetadataId,
    hasHistoryRoutingForBibTitles,
    hasHistoryRoutingForNavLinks,
    listsById,
    trackBibItemClick,
    trackBibsRowTitleClick,
    trackLinksRowLinkClick,
    trackListCreatorClick,
    trackListImageClick,
    trackListImpression,
    trackListTitleClick,
    trackNavLinkClick,
    usersById
  }
) => {
  if (!browseConfigElements.length) {
    return [];
  }

  return browseConfigElements
    .map((row, index) => {
      const hasAlternativeStyle = row?.config?.alternateRowStyle ?? false;
      const type = row.type;
      const title = row.title || row?.config?.title || '';
      const rowConfigId = uniqueId(`${type}_`);
      const rowPosition = index + 1;

      let config = null;

      if (type === BROWSE_ROW_TYPE.BIB) {
        const displayBibs = (row.config?.metadataIds ?? []).map(metadataId => {
          return mapCatalogBibToDisplayBib(catalogBibsByMetadataId?.[metadataId]);
        });
        const configTitleUrl = row?.config?.url;

        config = {
          displayBibs,
          hasHistoryRoutingForTitles: hasHistoryRoutingForBibTitles,
          title,
          titleUrl: configTitleUrl,
          trackBibClick: displayBib => {
            trackBibItemClick({ ...displayBib, rowPosition, rowTitle: title });
          },
          trackTitleClick: ({ titleText, titleUrl }) => {
            trackBibsRowTitleClick({ linkText: titleText, linkUrl: titleUrl, rowPosition, rowTitle: title });
          }
        };
      } else if (type === BROWSE_ROW_TYPE.LINK) {
        const links = row?.config?.links ?? [];
        const configTitle = row?.config?.title ?? '';

        config = {
          links,
          title: configTitle,
          trackClick: ({ itemPosition, linkText, linkUrl }) => {
            trackLinksRowLinkClick({ itemPosition, linkText, linkUrl, rowPosition, rowTitle: title });
          }
        };
      } else if (type === BROWSE_ROW_TYPE.LIST) {
        const lists = (row?.config?.rowContentIds ?? [])
          .map(listId => {
            return listsById?.[listId];
          })
          .filter(Boolean);
        const usersByListId = (row?.config?.rowContentIds ?? []).reduce((acc, listId) => {
          const list = listsById?.[listId];
          if (!list) {
            return acc;
          }

          const creatorUserId = list.owner;

          acc[listId] = usersById?.[String(creatorUserId)];

          return acc;
        }, {});

        config = {
          lists,
          title,
          trackListCreatorClick: itemPayload => trackListCreatorClick({ ...itemPayload, rowPosition, rowTitle: title }),
          trackListImageClick: itemPayload => trackListImageClick({ ...itemPayload, rowPosition, rowTitle: title }),
          trackListTitleClick: itemPayload => trackListTitleClick({ ...itemPayload, rowPosition, rowTitle: title }),
          trackListImpression: itemPayload => trackListImpression({ ...itemPayload, rowPosition, rowTitle: title }),
          usersByListId
        };
      } else if (type === BROWSE_ROW_TYPE.NAV) {
        const links = (row?.config?.links ?? []).map(link => {
          const { title: configTitle, url } = link;

          return { isRouterLink: hasHistoryRoutingForNavLinks, title: configTitle, url };
        });
        config = {
          links,
          title,
          trackClick: trackNavLinkClick
        };
      }
      return { config, hasAlternativeStyle, rowConfigId, rowPosition, type };
    })
    .filter(Boolean);
};

const mapCatalogSearchFieldsToNewFacets = (
  catalogSearchFields,
  {
    defaultFilterIdByFieldId = {},
    formatFieldLabel,
    formatFilterLabel,
    formatGroupLabel,
    formatShowMoreOverlayTitle,
    orderedFieldIds,
    orderedGroupIdsByFieldId,
    searchParams,
    searchScopes = [],
    showMoreFieldId = null
  }
) => {
  const catalogSearchFieldById = keyBy(catalogSearchFields, field => field.id);

  return orderedFieldIds
    .map(fieldId => {
      const isScopeField = fieldId === FIELD_ID.SCOPE;
      const hasManyScopes = searchScopes.length > 1;

      if (isScopeField && hasManyScopes) {
        return {
          id: FIELD_ID.SCOPE,
          hasMore: false,
          fieldFilters: searchScopes.map(scope => {
            const { scopeId: value, scopeName: label } = scope;

            return {
              applied: false,
              count: null,
              groupIds: [],
              label,
              value
            };
          })
        };
      }

      return catalogSearchFieldById[fieldId];
    })
    .filter(Boolean)
    .map(catalogSearchField => {
      const { id: fieldId, fieldFilters: filters, hasMore: fieldHasMore } = catalogSearchField;

      const hasShowMore = fieldId !== FIELD_ID.FORMAT && fieldHasMore;

      const groupsById = filters.reduce((acc, fieldFilter, index) => {
        const { count: numResults, groupIds, label: filterLabel, value: filterId } = fieldFilter;
        const filtersType = FILTERS_TYPE_BY_FIELD_ID[fieldId];
        const isDefault = isDefaultFilter({
          defaultFilterId: defaultFilterIdByFieldId[fieldId],
          filterId,
          filtersType,
          index
        });
        const isApplied = isAppliedFilter({ fieldId, filterId, isDefault, searchParams });
        const isExclusive = filtersType === FILTERS_TYPE.RADIO_BUTTONS;

        (groupIds.length ? groupIds : [GROUP_ID.UNGROUPED]).forEach(groupId => {
          if (!acc[groupId]) {
            acc[groupId] = {
              filters: [],
              filtersType,
              groupId,
              label: formatGroupLabel({ fieldId, groupId })
            };
          }

          const isShown =
            groupId !== GROUP_ID.UNGROUPED ||
            isApplied ||
            acc[groupId].filters.length < MAX_NUM_SHOWN_FILTERS_IN_CONDENSED_VIEW;

          const filter = {
            filterId,
            fieldId,
            groupId,
            isApplied,
            isDefault,
            isExclusive,
            isShown,
            label: formatFilterLabel({ fieldId, filterId, filterLabel, numResults })
          };

          acc[groupId].filters.push(filter);
        });

        return acc;
      }, {});

      const groups = (orderedGroupIdsByFieldId[fieldId] || Object.keys(groupsById))
        .map(groupId => groupsById[groupId])
        .filter(Boolean);

      return {
        fieldId,
        hasMore: hasShowMore, // TODO: rename key
        isInitiallyExpanded: false, // TODO: this will need to be configurable for Search Page
        isShowingMore: showMoreFieldId === fieldId,
        groups,
        label: formatFieldLabel({ fieldId }),
        numAppliedFilters: getNumAppliedFilters(fieldId, groups),
        showMoreOverlayTitle: formatShowMoreOverlayTitle(fieldId)
      };
    });
};

const mapQuerySearchParamsToCatalogSearchFilters = (searchParams, { fieldIds }) => {
  if (!fieldIds?.length) {
    throw new Error('`fieldIds` array option must have a non-zero length');
  }

  const fieldKeys = fieldIds.map(serializeFieldIdForQuerystring);

  return Array.from(searchParams.keys()).reduce((acc, key) => {
    const isPageKey = key === PAGE_KEY;
    const isScopeKey = key === SCOPE_FIELD_QUERY_PARM_KEY;

    const isValidKey = isPageKey || fieldKeys.includes(key);
    if (!isValidKey) {
      return acc;
    }

    if (isPageKey) {
      const pageNum = Number(searchParams.get(PAGE_KEY));

      if (Number.isInteger(pageNum)) {
        acc[PAGE_KEY] = pageNum;
      }
    } else if (isScopeKey) {
      acc[SCOPE_FIELD_QUERY_PARM_KEY] = searchParams.get(SCOPE_FIELD_QUERY_PARM_KEY);
    } else {
      const serializedKey = FILTER_PREFIX.concat(key.toUpperCase());

      acc[serializedKey] = searchParams.getAll(key).join(FILTER_SEPARATOR);
    }

    return acc;
  }, {});
};

const groupFiltersByIsApplied = filters => {
  return {
    applied: filters.filter(filter => filter.isApplied),
    unapplied: filters.filter(filter => !filter.isApplied)
  };
};

export {
  getUniqueAppliedFilters,
  groupFiltersByIsApplied,
  makeCategoryIndex,
  makeClearedSearchParams,
  makeFieldLabel,
  makeFilterLabel,
  makeGroupLabel,
  makeNewSearchParams,
  makeTabLinkUrl,
  mapBrowseConfigElementsToRowConfigs,
  mapCategoriesToNavRowLinks,
  mapCatalogSearchFieldsToNewFacets,
  mapQuerySearchParamsToCatalogSearchFilters
};
