import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export const METRIC_UNIT = 'SI';
export const EVENTS_URL = '/v2/events';
export const MY_EVENTS_BASE_URL = '/v2/events/account';
export const EVENTS_PDF_URL = '/v2/events/pdf';
export const BROCHURE_LIMIT = 200;
export const FILTER_SEPARATOR = ',';

export const SUB_CATEGORIES_ORDER = ['onlineLocations', 'preferredLocations', 'otherLocations'];

export const EVENTS_FACET_FIELDS = {
  EVENT_DATES: 'dates',
  BRANCH_LOCATIONS: 'locations',
  EVENT_TYPES: 'types',
  EVENT_PROGRAMS: 'programs',
  EVENT_AUDIENCES: 'audiences',
  EVENT_LANGUAGES: 'languages'
};

export const FACET_FIELDS_ORDER = [
  EVENTS_FACET_FIELDS.EVENT_DATES,
  EVENTS_FACET_FIELDS.BRANCH_LOCATIONS,
  EVENTS_FACET_FIELDS.EVENT_AUDIENCES,
  EVENTS_FACET_FIELDS.EVENT_TYPES,
  EVENTS_FACET_FIELDS.EVENT_PROGRAMS,
  EVENTS_FACET_FIELDS.EVENT_LANGUAGES
];

export default createReduxConstants('EVENTS', {
  ADD_INTERESTED_EVENTS_REQUEST: null,
  ADD_INTERESTED_EVENTS_SUCCESS: null,
  ADD_INTERESTED_EVENTS_FAILURE: null,
  BROCHURE_EVENTS_REQUEST: null,
  BROCHURE_EVENTS_SUCCESS: null,
  BROCHURE_EVENTS_FAILURE: null,
  EVENT_BY_ID_REQUEST: null,
  EVENT_BY_ID_SUCCESS: null,
  EVENT_BY_ID_FAILURE: null,
  FETCH_CALLOUT_FEATURED_EVENT_REQUEST: null,
  FETCH_CALLOUT_FEATURED_EVENT_SUCCESS: null,
  FETCH_CALLOUT_FEATURED_EVENT_FAILURE: null,
  MY_EVENTS_REQUEST: null,
  MY_EVENTS_SUCCESS: null,
  MY_EVENTS_FAILURE: null,
  MY_EVENTS_CANCEL_REQUEST: null,
  MY_EVENTS_CANCEL_SUCCESS: null,
  MY_EVENTS_CANCEL_FAILURE: null,
  FETCH_INTERESTED_EVENTS_REQUEST: null,
  FETCH_INTERESTED_EVENTS_SUCCESS: null,
  FETCH_INTERESTED_EVENTS_FAILURE: null,
  REMOVE_INTERESTED_EVENTS_REQUEST: null,
  REMOVE_INTERESTED_EVENTS_SUCCESS: null,
  REMOVE_INTERESTED_EVENTS_FAILURE: null,
  SEARCH_EVENTS_REQUEST: null,
  SEARCH_EVENTS_SUCCESS: null,
  SEARCH_EVENTS_FAILURE: null
});

export const PROVIDERS = {
  BIBLIO_EVENTS: 'BIBLIO_EVENTS',
  EXTERNAL: 'EXTERNAL'
};

export const REGISTRATION_WINDOW_STATUSES = {
  ACTIVE: 'ACTIVE',
  UPCOMING: 'UPCOMING',
  ENDED: 'ENDED'
};

export const MY_EVENTS_FACET_ID = {
  EVENT_DATE: 'EVENT_DATE',
  SERIES: 'SERIES'
};

export const MY_EVENTS_FACET_CATEGORIES = [MY_EVENTS_FACET_ID.EVENT_DATE];

export const DATE_FACETS = {
  EVENT_ALL_DATES: 'event_all_dates',
  TODAY: 'today',
  THIS_WEEK: 'this_week',
  WEEKEND: 'weekend',
  NEXT_WEEK: 'next_week'
};
export const DATE_FACETS_ORDER = ['EVENT_ALL_DATES', 'TODAY', 'THIS_WEEK', 'WEEKEND', 'NEXT_WEEK'];

export const TAXONOMY_DISPLAY_TYPE = {
  LINK: 'link',
  PILL: 'pill'
};
