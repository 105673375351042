import React from 'react';
import { Link } from 'react-router-dom';

import { ChevronRight } from '@bibliocommons/base-icons';
import Heading from '@bibliocommons/heading';

import { displayBibsRowConfigShape } from 'app/shapes/browse';
import DisplayBibSlide from './DisplayBibSlide';

import './DisplayBibsRow.scss';

const DisplayBibsRow = ({ config }) => {
  const { displayBibs, hasHistoryRoutingForTitles, title, titleUrl, trackBibClick, trackTitleClick } = config;

  const onTitleClick = () => {
    trackTitleClick({ titleText: title, titleUrl });
  };

  return (
    <div className="cp-display-bibs-row row">
      <div className="col-12">
        <Heading className="heading" tagName="h2" size="moderate" variant>
          {hasHistoryRoutingForTitles ? (
            <Link dataKey={titleUrl} onAuxClick={onTitleClick} onClick={onTitleClick} to={titleUrl}>
              {title}
              <ChevronRight />
            </Link>
          ) : (
            <a href={titleUrl} onAuxClick={onTitleClick} onClick={onTitleClick}>
              {title}
              <ChevronRight />
            </a>
          )}
        </Heading>
        <ul className="bibs-row__list">
          {displayBibs.map((displayBib, index) => {
            const itemPosition = index + 1; // + 1 so that the count is 1-based.

            return (
              <li className="bibs-row__item" key={displayBib.metadataId}>
                <DisplayBibSlide
                  className="bibs-row__slide"
                  displayBib={displayBib}
                  trackClick={() => trackBibClick({ displayBib, itemPosition })}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

DisplayBibsRow.propTypes = {
  config: displayBibsRowConfigShape.isRequired
};

export default DisplayBibsRow;
