import React from 'react';

import {
  FullScreenOverlayBody,
  FullScreenOverlayContent,
  FullScreenOverlayHeader
} from '@bibliocommons/base-full-screen-overlay';
import PropTypes from 'prop-types';

import { groupShape } from 'app/shapes/browse';
import Group from '../Group';
import LoadingStencil from './LoadingStencil';

import './ShowMoreOverlay.scss';

const ShowMoreOverlay = ({ group, handleFilterChange, isFetching, renderBackButton, title }) => {
  return (
    <fieldset>
      <FullScreenOverlayBody>
        <FullScreenOverlayHeader title={<legend style={{ fontSize: 'inherit', margin: 0 }}>{title}</legend>} />
        <FullScreenOverlayContent size="small">
          <div className="cp-facets-shared-show-more-overlay">
            {renderBackButton()}
            {isFetching ? (
              <LoadingStencil />
            ) : (
              <section className="groups">
                <Group group={group} handleFilterChange={handleFilterChange} isMultiColumn isShowAll />
              </section>
            )}
          </div>
        </FullScreenOverlayContent>
      </FullScreenOverlayBody>
    </fieldset>
  );
};

ShowMoreOverlay.propTypes = {
  group: groupShape.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  renderBackButton: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default ShowMoreOverlay;
