import cn from 'classnames';
import React from 'react';

import { BROWSE_ROW_TYPE } from 'app/constants/BrowseConstants';
import { rowConfigsShape } from 'app/shapes/browse';

import DisplayBibsRow from './DisplayBibsRow';
import LinkRow from './LinkRow';
import ListsRow from './ListsRow';
import NavRow from './NavRow';

import './Rows.scss';

const Rows = ({ rowConfigs }) => {
  const renderRow = rowConfig => {
    const { config, type } = rowConfig;

    switch (type) {
      case BROWSE_ROW_TYPE.BIB: {
        return <DisplayBibsRow config={config} />;
      }
      case BROWSE_ROW_TYPE.LINK:
        return <LinkRow config={config} />;
      case BROWSE_ROW_TYPE.LIST: {
        return <ListsRow config={config} />;
      }
      case BROWSE_ROW_TYPE.NAV: {
        return <NavRow config={config} />;
      }
      default:
        throw new Error(`Unknow row type: ${type}`);
    }
  };

  return (
    <div className="cp-browse-rows">
      {rowConfigs.map(rowConfig => {
        const { hasAlternativeStyle, rowConfigId, type } = rowConfig;

        const isNav = type === BROWSE_ROW_TYPE.NAV;

        return (
          <section
            className={cn('browse-rows__row', {
              'browse-rows__row--alt': hasAlternativeStyle,
              'browse-rows__row--nav': isNav
            })}
            key={rowConfigId}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">{renderRow(rowConfig)}</div>
              </div>
            </div>
          </section>
        );
      })}
    </div>
  );
};

Rows.propTypes = {
  rowConfigs: rowConfigsShape.isRequired
};

export default Rows;
