export const AUDIENCE = {
  ADULT: 'ADULT',
  JUVENILE: 'JUVENILE',
  TEEN: 'TEEN',
  UNKNOWN: 'UNKNOWN'
};

export const CATALOG_RECORD_BIB_IMAGE_INFO_TYPE = {
  LOCAL: 'LOCAL',
  NONLOCAL: 'NONLOCAL'
};

export const CATALOG_RECORD_BIB_TITLE_TYPE = {
  MANIFESTATION: 'MANIFESTATION',
  VARIANT: 'VARIANT'
};

export const CONTENT_TYPE = {
  FICTION: 'FICTION',
  GOVERNMENT_DOCUMENTS: 'GOVERNMENT_DOCUMENTS',
  NONFICTION: 'NONFICTION',
  UNDETERMINED: 'UNDETERMINED'
};

// TODO: build-out the enum of supported bib format IDs
export const FORMAT_ID = {
  ARCHIVAL_CONTAINER: 'ARCHIVAL_CONTAINER',
  ARCHIVAL_OBJECT_DIGITAL: 'ARCHIVAL_OBJECT_DIGITAL',
  ATLAS: 'ATLAS',
  AUDIOBOOK: 'AB',
  BOARD_BOOK: 'BOARD_BK',
  BOOK: 'BK',
  BOOK_CD: 'BOOK_CD',
  BOOK_PLUS_AUDIO: 'BOOK_PAUDIO',
  DVD: 'DVD',
  EBOOK: 'EBOOK',
  EASY_READER: 'EASY_READER',
  GRAPHIC_NOVEL: 'GRAPHIC_NOVEL',
  KIT: 'KIT',
  LAPTOP: 'LAPTOP',
  LARGE_PRINT: 'LPRINT',
  LONG_PLAY: 'LP',
  MAP: 'MAP',
  MICROFORM: 'MF',
  MIXED_MATERIAL: 'MIXED_MATERIAL',
  MUSIC_CD: 'MUSIC_CD',
  ONLINE_IMAGE: 'ONLINE_IMAGE',
  PAPERBACK: 'PAPERBACK',
  PRINTED_MUSIC: 'MN',
  STREAMING_MUSIC: 'MUSIC_ONLINE',
  UNKNOWN: 'UK',
  VIDEO_ONLINE: 'VIDEO_ONLINE',
  WEBSITE: 'WEBSITE',
  ZINE: 'ZINE'
};

export const FULFILLMENT_PROVIDER = {
  HOOPLA: 'HooplaAPI',
  ILS: 'ILS',
  OVER_DRIVE: 'OverDriveAPI'
};

export const TITLE_TYPE = {
  MANIFESTATION: 'MANIFESTATION',
  VARIANT: 'VARIANT'
};
