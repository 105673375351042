import PropTypes from 'prop-types';
import React from 'react';

import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';

import { rowConfigsShape } from 'app/shapes/browse';
import Rows from '../shared/rows/Rows';

import './BrowsePage.scss';

const BrowsePage = ({ pageTitle, rowConfigs }) => {
  return (
    <div className="cp-browse-page">
      <ScreenReaderMessage>
        <h1>{pageTitle}</h1>
      </ScreenReaderMessage>
      <Rows rowConfigs={rowConfigs} />
    </div>
  );
};

BrowsePage.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  rowConfigs: rowConfigsShape.isRequired
};

export default BrowsePage;
