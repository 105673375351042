import React from 'react';

import Heading from '@bibliocommons/heading';
import { PrimarySolidLink } from '@bibliocommons/base-links';

import { linkRowConfigShape } from 'app/shapes/browse';

import './LinkRow.scss';

export default function LinkRow({ config }) {
  const { links, title, trackClick } = config;

  function renderLinks(link, index) {
    const itemPosition = index + 1; // 1-based count for analytics

    const { title: linkText, url: linkUrl } = link;

    return (
      <li className="link-content" key={linkUrl}>
        <PrimarySolidLink
          handleClick={() => trackClick({ itemPosition, linkText, linkUrl })}
          href={linkUrl}
          dataKey="link-primary"
          className="link-primary"
          size="large"
        >
          {linkText}
        </PrimarySolidLink>
      </li>
    );
  }

  return (
    <div className="cp-link-row">
      <Heading tagName="h2" size="moderate" variant>
        <span>{title}</span>
      </Heading>
      <ul className="link-row-contents">{links.map(renderLinks)}</ul>
    </div>
  );
}

LinkRow.propTypes = {
  config: linkRowConfigShape.isRequired
};
