import { createSelector } from 'reselect';
import Immutable from 'immutable';

const allBibs = state => state.getIn(['entities', 'bibs']);
const getCatalogSearch = state => state.getIn(['search', 'catalogSearch']);
const searchResultIds = state => state.getIn(['search', 'catalogSearch', 'results']);
const addTitleFromState = state => state.getIn(['search', 'addTitle']);
export const probeResults = state => state.getIn(['search', 'catalogSearch', 'probes']);

/**
 * [selectSearchResultBib selects bib entites corresponding to values in the search result object]
 * @return selector
 */
export const selectSearchResultBib = createSelector(searchResultIds, allBibs, (results, bibs) =>
  results.map(result => ({
    representative: bibs.get(result.get('representative')),
    manifestations: result.get('manifestations').map(i => bibs.get(i))
  }))
);

/**
 * @description builds results for addTitle search; items is a list of corresponding bibs
 * @return selector
 */
export const selectAddTitleResult = createSelector(allBibs, addTitleFromState, (bibs, addTitle) =>
  Immutable.Map({
    searchQuery: addTitle.get('searchQuery'),
    isFetching: addTitle.get('isFetching'),
    zeroResults: addTitle.get('zeroResults'),
    pagination: addTitle.get('pagination'),
    items: addTitle.get('results').flatMap(result => result.get('manifestations').map(i => bibs.get(i)))
  })
);

export const selectShowMoreFiltersFieldId = createSelector([getCatalogSearch], catalogSearch => {
  return catalogSearch.get('showMoreFiltersFieldId');
});
