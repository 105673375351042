import moment from 'moment';

import { setCookie } from '@bibliocommons/utils-browser';

import { selectShowMoreFiltersFieldId } from 'app/selectors/SearchSelector';
import SearchApi from '../api/SearchApi';
import SearchConstants, { SEARCH_VIEW_COOKIE_NAME } from '../constants/SearchConstants';

const GROUPING_COOKIE = 'catalog_search_show_groupings';

function setShowMoreFiltersFieldId(fieldId) {
  return {
    type: SearchConstants.SET_SHOW_MORE_FILTERS_FIELD_ID,
    payload: {
      fieldId
    }
  };
}

function expandField(fieldId, query) {
  return dispatch => {
    dispatch(setShowMoreFiltersFieldId(fieldId));

    if (fieldId) {
      dispatch({
        types: [
          SearchConstants.EXPAND_FIELD_REQUEST,
          SearchConstants.EXPAND_FIELD_SUCCESS,
          SearchConstants.EXPAND_FIELD_FAILURE
        ],
        apiRequest: (_state, apiClient) => {
          return SearchApi.fetchAdditionalFilters(query, fieldId, apiClient);
        }
      });
    }
  };
}

// The `isPersistShowMoreFiltersFieldId` is needed because newer pages that use search
// (ie., Browse Pages) are in control of setting the `showMoreFiltersFieldId` in the global state.
// The old Search Page, on the other hand, doesn't always know when the Show More overlay is closed,
// and so we have additional logic in this action to remove `showMoreFiltersFieldId` when the
// API response has `hasMore` false.
function performCatalogSearch(
  searchQuery,
  { isPersistShowMoreFiltersFieldId } = { isPersistShowMoreFiltersFieldId: false }
) {
  return {
    searchQuery,
    types: [SearchConstants.SEARCH_REQUEST, SearchConstants.SEARCH_SUCCESS, SearchConstants.SEARCH_FAILURE],
    apiRequest: (_state, apiClient) => SearchApi.fetchCatalogResults(searchQuery, apiClient),
    apiSuccess: (response, { dispatch, getState }) => {
      const showMoreFiltersFieldId = selectShowMoreFiltersFieldId(getState());
      const hasMore =
        (response?.catalogSearch?.fields || []).find(field => field.id === showMoreFiltersFieldId)?.hasMore ?? false;

      if (showMoreFiltersFieldId && hasMore) {
        dispatch(expandField(showMoreFiltersFieldId, searchQuery));
      } else if (!isPersistShowMoreFiltersFieldId) {
        // The Show More overlay on the Search Page will close if `hasMore` is false
        // So we need to clear the `showMoreFiltersFieldId` in state to remain consistent with the UI.
        dispatch(setShowMoreFiltersFieldId(null));
      }
    }
  };
}

function performShelfAvailabilitySearch(searchQuery) {
  return {
    searchQuery,
    types: [
      SearchConstants.SHELF_AVAILABILITY_SEARCH_REQUEST,
      SearchConstants.SHELF_AVAILABILITY_SEARCH_SUCCESS,
      SearchConstants.SHELF_AVAILABILITY_SEARCH_FAILURE
    ],
    apiRequest: (state, apiClient) =>
      SearchApi.fetchShelfAvailabilityResults(
        {
          ...searchQuery,
          view: 'grouped'
        },
        apiClient
      )
  };
}

function addTitleSearch(searchQuery) {
  return {
    searchQuery,
    types: [
      SearchConstants.ADD_TITLE_SEARCH_REQUEST,
      SearchConstants.ADD_TITLE_SEARCH_SUCCESS,
      SearchConstants.ADD_TITLE_SEARCH_FAILURE
    ],
    apiRequest: (state, apiClient) => SearchApi.fetchCatalogResults({ ...searchQuery, view: 'medium' }, apiClient)
  };
}

function getSearchProbes(query, searchType) {
  return {
    types: [
      SearchConstants.SEARCH_PROBE_REQUEST,
      SearchConstants.SEARCH_PROBE_SUCCESS,
      SearchConstants.SEARCH_PROBE_FAILURE
    ],
    apiRequest: (state, client) => SearchApi.fetchSearchProbes(query, searchType, client)
  };
}

function toggleSearchView(view) {
  const expires = moment()
    .add(10, 'years')
    .toDate();
  setCookie(SEARCH_VIEW_COOKIE_NAME, view, { expires });
  return {
    type: SearchConstants.TOGGLE_SEARCH_VIEW,
    view
  };
}

function toggleShowGroupings(newState) {
  setCookie(GROUPING_COOKIE, newState || '');
  return {
    type: SearchConstants.TOGGLE_SHOW_GROUPINGS
  };
}

function getGroupSearchSummaries(metadataIds) {
  return {
    types: [
      SearchConstants.GROUP_SEARCH_SUMMARY_REQUEST,
      SearchConstants.GROUP_SEARCH_SUMMARY_SUCCESS,
      SearchConstants.GROUP_SEARCH_SUMMARY_FAILURE
    ],
    apiRequest: (state, apiClient) =>
      SearchApi.fetchGroupSearchSummaries(metadataIds, state.getIn(['library', 'libraryId']), apiClient)
  };
}

function setShouldPreventImmediateRefetch(shouldPreventImmediateRefetch) {
  return {
    type: SearchConstants.SET_SHOULD_PREVENT_IMMEDIATE_REFETCH,
    payload: {
      shouldPreventImmediateRefetch
    }
  };
}

export default {
  expandField,
  getGroupSearchSummaries,
  getSearchProbes,
  addTitleSearch,
  toggleSearchView,
  toggleShowGroupings,
  performCatalogSearch,
  performShelfAvailabilitySearch,
  setShouldPreventImmediateRefetch,
  setShowMoreFiltersFieldId
};

export { expandField, performCatalogSearch };
