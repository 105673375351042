import Immutable from 'immutable';
import { combineReducers } from 'redux-immutablejs';

import eventsSearch from './EventsSearchReducer';
import myEvents from './MyEventsReducer';
import eventsBrochure from './EventsBrochureReducer';
import eventsDetails from './EventsDetailsReducer';

export default combineReducers(
  Immutable.Map({
    eventsBrochure,
    eventsDetails,
    eventsSearch,
    myEvents
  })
);
