import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export const actionTypes = createReduxConstants('ANALYTICS_GA4', {
  SET_SDK_CONFIG: null,
  SET_SDK_PAGE_CONFIG: null,
  SET_SEARCH_ORIGIN: null,
  TRACK_CATALOG_EXPLORE: null,
  TRACK_CATALOG_SEARCH: null,
  TRACK_CHECKOUT_CONSUME_ACCESS: null,
  TRACK_CHECKOUT_CONSUME_DOWNLOAD: null,
  TRACK_CONTENT_ENGAGE_LIKE: null,
  TRACK_CONTENT_EXPLORE: null,
  TRACK_CONTENT_CLICK: null,
  TRACK_CONTENT_COLLECTION_MOVE: null,
  TRACK_CONTENT_IMPRESSION: null,
  TRACK_EVENT_INTERESTED: null,
  TRACK_EVENT_PDF_CREATE: null,
  TRACK_EVENT_SEARCH: null,
  TRACK_HOLDINGS_CONSUME_ACCESS: null,
  TRACK_HOLDINGS_EXPLORE: null,
  TRACK_HOLDINGS_EXPLORE_EDITION: null,
  TRACK_HOLDINGS_EXPLORE_FORMAT: null,
  TRACK_HOLDINGS_VIEW: null,
  TRACK_HOLDINGS_WAYFINDER_VIEW: null,
  TRACK_NAVIGATE: null,
  TRACK_PAGE_PRINT: null,
  TRACK_PAGE_VIEW: null,
  TRACK_REQUEST_EXTERNAL_PLACE: null,
  TRACK_SDK_CHECKOUT_CONSUME_ACCESS: null,
  TRACK_SDK_CHECKOUT_PLACE: null,
  TRACK_SDK_HOLD_PLACE: null,
  TRACK_SDK_HOLDINGS_CONSUME_ACCESS: null,
  TRACK_SDK_HOLDINGS_VIEW: null,
  TRACK_SDK_LOG_IN: null,
  TRACK_SDK_USER_ACCOUNT_REGISTER: null,
  TRACK_SDK_USER_CONTENT_ADD: null,
  TRACK_SDK_USER_CONTENT_MODIFY: null,
  TRACK_SDK_USER_ENGAGE_FOLLOW: null
});

export const eventNames = {
  CATALOG_EXPLORE: 'bc_catalog_explore',
  CATALOG_SEARCH: 'bc_catalog_search',
  CHECKOUT_CONSUME_ACCESS: 'bc_checkout_consume_access',
  CHECKOUT_CONSUME_DOWNLOAD: 'bc_checkout_consume_download',
  CHECKOUT_PLACE: 'bc_checkout_place',
  CHECKOUT_RENEW: 'bc_checkout_renew',
  CONTENT_CLICK: 'bc_content_click',
  CONTENT_COLLECTION_ADD: 'bc_content_collection_add',
  CONTENT_COLLECTION_MOVE: 'bc_content_collection_move',
  CONTENT_ENGAGE_LIKE: 'bc_content_engage_like',
  CONTENT_EXPLORE: 'bc_content_explore',
  CONTENT_IMPRESSION: 'bc_content_impression',
  EVENT_INTERESTED: 'bc_event_interested',
  EVENT_PDF_CREATE: 'bc_event_pdf_create',
  EVENT_SEARCH: 'bc_event_search',
  HOLD_PLACE: 'bc_hold_place',
  HOLDINGS_CONSUME_ACCESS: 'bc_holdings_consume_access',
  HOLDINGS_EXPLORE: 'bc_holdings_explore',
  HOLDINGS_EXPLORE_EDITION: 'bc_holdings_explore_edition',
  HOLDINGS_EXPLORE_FORMAT: 'bc_holdings_explore_format',
  HOLDINGS_VIEW: 'bc_holdings_view',
  HOLDINGS_WAYFINDER_VIEW: 'bc_holdings_wayfinder_view',
  NAVIGATE: 'bc_navigate',
  PAGE_PRINT: 'bc_page_print',
  PAGE_VIEW: 'bc_page_view',
  REQUEST_EXTERNAL_PLACE: 'bc_request_external_place',
  SERVICE_FEEDBACK: 'bc_service_feedback',
  USER_ACCOUNT_REGISTER: 'bc_user_account_register',
  USER_CONTENT_ADD: 'bc_user_content_add',
  USER_CONTENT_MODIFY: 'bc_user_content_modify',
  USER_ENGAGE_FOLLOW: 'bc_user_engage_follow',
  USER_LOG_IN: 'bc_user_log_in'
};

export const CONTENT_GROUP = {
  BIB: 'bib',
  BIB_COMMENT: 'bib_comment',
  BIB_CONTENT_NOTICE: 'bib_content_notice',
  BIB_LIST: 'bib_list',
  BIB_QUOTATION: 'bib_quotation',
  BIB_RECOMMENDATION: 'bib_recommendation',
  BIB_REVIEW: 'bib_review',
  BIB_SUMMARY: 'bib_summary',
  BIB_VIDEO: 'bib_video',
  BORROWING_CHECKED_OUT: 'user_account_borrowing_checked_out',
  BORROWING_FEES: 'user_account_borrowing_fees',
  BORROWING_HISTORY: 'user_account_borrowing_history',
  BORROWING_ILL_REQUESTS: 'user_account_borrowing_ill_requests',
  BORROWING_LIBRARY_MESSAGES: 'user_account_borrowing_library_messages',
  BORROWING_ON_HOLD: 'user_account_borrowing_on_hold',
  BROWSE_HOME: 'browse_home',
  BROWSE_TITLES_NEW: 'browse_titles_new',
  BROWSE_TITLES_ON_ORDER: 'browse_titles_on_order',
  CATALOG_SEARCH: 'catalog_search',
  COMMUNITY_CONTENT_COMMENT: 'community_content_comment',
  COMMUNITY_CONTENT_QUOTATION: 'community_content_quotation',
  COMMUNITY_CONTENT_SUMMARY: 'community_content_summary',
  COMMUNITY_CONTENT_VIDEO: 'community_content_video',
  EVENT_LISTING: 'event_listing',
  LIST: 'list',
  LOCATION_LISTING: 'location_listing',
  SHELVES_COMPLETED: 'user_account_shelves_completed',
  SHELVES_FOR_LATER: 'user_account_shelves_for_later',
  SHELVES_IN_PROGRESS: 'user_account_shelves_in_progress',
  USER_ACCOUNT_EVENT: 'user_account_event'
};

// TODO: populate this enum
export const METADATA_CATEGORY_TYPE = {
  BIBLIOGRAPHIC_DETAILS_CREATORS: 'bibliographic_details_creators',
  BIBLIOGRAPHIC_DETAILS_TITLE: 'bibliographic_details_title',
  BROWSE: 'browse',
  HOLDINGS_ELECTRONIC_RESOURCE: 'holdings_electronic_resource',
  HOLDINGS_ELECTRONIC_RESOURCE_LIST: 'holdings_electronic_resource_list',
  HOLDINGS_RESOURCE_ITEM_ID: 'holdings_resource_item_id',
  NAVIGATION: 'navigation',
  USER: 'user'
};

// TODO: populate this enum
export const UI_COMPONENT_TYPE = {
  BUTTON: 'button',
  LINK_HEADING: 'link-heading',
  LINK_IMAGE: 'link-image',
  LINK_TEXT: 'link-text'
};

// TODO: populate this enum
export const UI_CONTAINER_TITLE = {
  IN_THE_SAME_SERIES: 'in-the-same-series',
  STAFF_LISTS_FEATURING_THIS_TITLE: 'staff-lists-featuring-this-title'
};

// TODO: populate this enum
export const UI_CONTAINER_TYPE = {
  BROWSE_ROW_BIB: 'browse-row-bib',
  BROWSE_ROW_LINK: 'browse-row-link',
  BROWSE_ROW_LIST: 'browse-row-list',
  BROWSE_ROW_NAV: 'browse-row-navigation',
  BROWSE_TITLES_NAV: 'browse-titles-navigation',
  ITEM: 'item',
  ITEM_ALL_FORMATS: 'item-all-formats',
  LIST_HEADER: 'list-header',
  SECTION: 'section',
  CARD: 'card'
};

export default {
  actionTypes,
  eventNames
};
