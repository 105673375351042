import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { BROWSE_ROW_TYPE, FIELD_ID, FILTERS_TYPE, GROUP_ID } from 'app/constants/BrowseConstants';
import displayBibShape from 'app/shapes/displayBib';

export const categoryShape = PropTypes.exact({
  categoryId: PropTypes.string.isRequired,
  parentCategoryId: PropTypes.string,
  queryParams: PropTypes.shape({
    query: PropTypes.string.isRequired,
    searchType: PropTypes.string.isRequired,
    sort: PropTypes.string
  }).isRequired,
  subCategoryIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired
});

export const categoryIndexShape = PropTypes.objectOf(categoryShape);
export const immutableCategoryIndexShape = ImmutablePropTypes.mapOf(
  ImmutablePropTypes.mapContains({
    categoryId: PropTypes.string.isRequired,
    parentCategoryId: PropTypes.string,
    queryParams: ImmutablePropTypes.mapContains({
      query: PropTypes.string.isRequired,
      searchType: PropTypes.string.isRequired,
      sort: PropTypes.string
    }).isRequired,
    subCategoryIds: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired
  }),
  PropTypes.string
);

// rows
export const displayBibsRowConfigShape = PropTypes.exact({
  displayBibs: PropTypes.arrayOf(displayBibShape).isRequired,
  hasHistoryRoutingForTitles: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleUrl: PropTypes.string.isRequired,
  trackBibClick: PropTypes.func,
  trackTitleClick: PropTypes.func
});

export const linkRowConfigShape = PropTypes.exact({
  links: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  trackClick: PropTypes.func.isRequired
});

export const listRowConfigShape = PropTypes.exact({
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string,
      imageUrl: PropTypes.string,
      listType: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      owner: PropTypes.number.isRequired
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  trackListCreatorClick: PropTypes.func.isRequired,
  trackListImageClick: PropTypes.func.isRequired,
  trackListImpression: PropTypes.func.isRequired,
  trackListTitleClick: PropTypes.func.isRequired,
  usersByListId: PropTypes.objectOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      staff: PropTypes.bool.isRequired
    })
  ).isRequired
});

export const navRowConfigShape = PropTypes.exact({
  links: PropTypes.arrayOf(
    PropTypes.exact({
      isRouterLink: PropTypes.bool,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  trackClick: PropTypes.func.isRequired
});

export const rowConfigsShape = PropTypes.arrayOf(
  PropTypes.exact({
    config: PropTypes.oneOfType([displayBibsRowConfigShape, linkRowConfigShape, listRowConfigShape, navRowConfigShape])
      .isRequired,
    hasAlternativeStyle: PropTypes.bool.isRequired,
    rowConfigId: PropTypes.string.isRequired,
    rowPosition: PropTypes.number.isRequired,
    type: PropTypes.oneOf(Object.values(BROWSE_ROW_TYPE)).isRequired
  })
);

// facets
const fieldIdShape = PropTypes.oneOf(Object.values(FIELD_ID));
const groupIdShape = PropTypes.oneOf(Object.values(GROUP_ID));

export const filterShape = PropTypes.shape({
  fieldId: fieldIdShape.isRequired,
  filterId: PropTypes.string.isRequired,
  groupId: groupIdShape.isRequired,
  isApplied: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool.isRequired,
  isExclusive: PropTypes.bool.isRequired,
  isShown: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
});

export const groupShape = PropTypes.shape({
  groupId: groupIdShape.isRequired,
  filters: PropTypes.arrayOf(filterShape).isRequired,
  filtersType: PropTypes.oneOf(Object.values(FILTERS_TYPE)).isRequired,
  label: PropTypes.string.isRequired
});

export const fieldShape = PropTypes.shape({
  fieldId: fieldIdShape.isRequired,
  hasMore: PropTypes.bool.isRequired,
  groups: PropTypes.arrayOf(groupShape).isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired,
  isShowingMore: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  numAppliedFilters: PropTypes.number.isRequired,
  showMoreOverlayTitle: PropTypes.string.isRequired
});
