import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorPage from 'app/components/ErrorPage';
import MainLayout from 'app/components/layouts/MainLayout';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import EventsDetailsPageRoute from './EventsDetailsPageRoute';

const basePath = '/v2/events/:eventId';

export default (
  <Route path={basePath}>
    <MainLayout>
      <Switch>
        <Route exact path={basePath}>
          <EventsDetailsPageRoute />
        </Route>
        <Route>
          <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
