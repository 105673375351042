import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { paginationShape } from '@bibliocommons/bc-prop-types';
import Heading from '@bibliocommons/heading';
import Pagination, { PaginationLabel } from '@bibliocommons/pagination';
import { RatingStars } from '@bibliocommons/rating';

import { BibJacket } from 'app/components/bibs/shared/BibJacket';
import { WINDOW_WIDTH_MIN_PX } from 'app/constants/window';
import { getUniqueAppliedFilters } from 'app/helpers/browse';
import { getRoundedRatingOutOf5 } from 'app/helpers/displayBib';
import useWindowWidth from 'app/hooks/useWindowWidth';
import AuthorLink from 'app/components/shared/AuthorLink';
import { fieldShape } from 'app/shapes/browse';
import displayBibShape from 'app/shapes/displayBib';
import AppliedFilters from '../facets/AppliedFilters';
import DesktopFacets from '../facets/Desktop';
import MobileFacets from '../facets/Mobile';

import './FilterableDisplayBibGrid.scss';

const DisplayBibRating = ({ ratingCount, ratingPercentage }) => {
  const ratingStarsScreenReaderLabel = (
    <FormattedMessage
      id="aggregate_ratings_count_sr_label"
      values={{ rating: getRoundedRatingOutOf5(ratingPercentage), count: ratingCount }}
    />
  );

  return <RatingStars rating={ratingPercentage} screenReaderLabel={ratingStarsScreenReaderLabel} />;
};

DisplayBibRating.propTypes = {
  ratingCount: PropTypes.number.isRequired,
  ratingPercentage: PropTypes.number.isRequired
};

const FilterableBibGrid = ({
  appliedFiltersAccessibilityHeading,
  displayBibs,
  facetFields,
  facetsHeading,
  handleFilterChange,
  hasBibRatings,
  isFetching,
  pagination,
  setShowMoreFieldId,
  trackAuthorClick,
  trackBibLinkClick,
  unapplyAllFilters
}) => {
  const windowWidthPx = useWindowWidth();

  const isDesktopWidth = windowWidthPx >= WINDOW_WIDTH_MIN_PX.LARGE;
  const hasAppliedFilters = getUniqueAppliedFilters(facetFields).length > 0;

  return (
    <div className="cp-browse-filterable-display-bib-grid row">
      <div className="col-lg-3">
        <div className="pagination-wrapper">
          <div className="pagination-label">
            <PaginationLabel autoFocus pagination={pagination} />
          </div>
          {!isDesktopWidth && (
            <div className="mobile-facets">
              <MobileFacets
                appliedFiltersAccessibilityHeadingMessage={appliedFiltersAccessibilityHeading}
                fields={facetFields}
                handleFilterChange={handleFilterChange}
                isFetching={isFetching}
                numTotalResults={pagination.get('count')}
                openOverlayClassName="open-overlay"
                overlayTitle={facetsHeading}
                setShowMoreFieldId={setShowMoreFieldId}
                unapplyAllFilters={unapplyAllFilters}
              />
            </div>
          )}
        </div>
        {isDesktopWidth && (
          <div className="desktop-facets">
            <DesktopFacets
              accessibilityHeading={facetsHeading}
              fields={facetFields}
              handleFilterChange={handleFilterChange}
              isFetching={isFetching}
              setShowMoreFieldId={setShowMoreFieldId}
            />
          </div>
        )}
      </div>

      <div className="col-lg-9">
        {hasAppliedFilters && (
          <section className="active-filters">
            <AppliedFilters
              accessibilityHeadingMessage={appliedFiltersAccessibilityHeading}
              accessibilityHeadingTag="h2"
              fields={facetFields}
              handleFilterChange={handleFilterChange}
              unapplyAllFilters={unapplyAllFilters}
            />
          </section>
        )}
        <ul className="row bib-list" data-key="display-bibs">
          {displayBibs.map((bib, index) => {
            const { authors, formatId, imageUrl, metadataId, ratingCount, ratingPercentage, subtitle, title } = bib;
            const linkText = `/v2/record/${metadataId}`;
            const itemPosition = index + 1; // 1-based count for analytics
            const IS_IMAGE = true;
            const IS_TEXT = false;
            const isShowBibRating = hasBibRatings && ratingCount > 0;

            return (
              <li className="col-12 col-xs-6 col-md-4 bib-list-item" key={metadataId}>
                <div className="bib-jacket-bg">
                  <div className="bib-jacket-wrapper">
                    <BibJacket
                      cover={Immutable.Map({ large: imageUrl })}
                      format={formatId}
                      metadataId={metadataId}
                      renderAsLink
                      handleClick={() => trackBibLinkClick(metadataId, title, IS_IMAGE, itemPosition)}
                    />
                  </div>
                </div>
                <Heading size="medium" tagName="h2" variant>
                  <a href={linkText} onClick={() => trackBibLinkClick(metadataId, title, IS_TEXT, itemPosition)}>
                    {title}
                  </a>
                </Heading>
                {subtitle && <div>{subtitle}</div>}
                <AuthorLink
                  authors={authors}
                  trackAuthorClick={({ author }) => trackAuthorClick({ metadataId, author, itemPosition })}
                />
                {isShowBibRating && (
                  <div className="rating">
                    <DisplayBibRating ratingCount={ratingCount} ratingPercentage={ratingPercentage} />
                  </div>
                )}
              </li>
            );
          })}
        </ul>

        <footer className="row">
          <div className="col-md-12">
            <Pagination isFetching={isFetching} pagination={pagination} showFirstPage showLastPage />
          </div>
        </footer>
      </div>
    </div>
  );
};

FilterableBibGrid.defaultProps = {
  hasBibRatings: false,
  trackAuthorClick: () => null,
  trackBibLinkClick: () => null
};

FilterableBibGrid.propTypes = {
  appliedFiltersAccessibilityHeading: PropTypes.string.isRequired,
  displayBibs: PropTypes.arrayOf(displayBibShape).isRequired,
  facetFields: PropTypes.arrayOf(fieldShape).isRequired,
  facetsHeading: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  hasBibRatings: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  pagination: paginationShape.isRequired,
  setShowMoreFieldId: PropTypes.func.isRequired,
  trackAuthorClick: PropTypes.func,
  trackBibLinkClick: PropTypes.func,
  unapplyAllFilters: PropTypes.func.isRequired
};

export default FilterableBibGrid;
