import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter, StaticRouter } from 'react-router-dom';

import { useRouter } from '@bibliocommons/utils-routing';
import Context from '@bibliocommons/context';
import { removeSessionStorageItem } from '@bibliocommons/utils-storage';

import { SEARCH_ORIGIN_QUERY_PARAM } from 'app/constants/AnalyticsConstants';
import { trackPageView } from 'app/actions/AnalyticsGa4Actions';
import { mapListToAnalyticsListEntity } from 'app/helpers/list';
import routes from './routes';

const Router = __SERVER__ ? StaticRouter : BrowserRouter;

export function Routes() {
  const { location, query } = useRouter();
  const { backToLinkEnabled, hydrated } = useContext(Context);
  const dispatch = useDispatch();
  const globalList = useSelector(state => state.getIn(['list', 'list']));

  useEffect(() => {
    // Skip analytics tracking if we know that
    // the page component will immediately perform a router.replace
    let isSkipTracking = location.pathname === '/v2' || location.pathname === '/v2/';
    isSkipTracking =
      isSkipTracking || (location.pathname === '/v2/search' && Boolean(query[SEARCH_ORIGIN_QUERY_PARAM]));

    let bibId = null;
    let list = null;

    if (location.pathname.startsWith('/v2/record/')) {
      bibId = /^\/v2\/record\/([^/]+)\/?/.exec(location.pathname)?.[1] ?? null;
    } else if (location.pathname.startsWith('/v2/list/display')) {
      list = mapListToAnalyticsListEntity(globalList?.toJS());
    }

    if (!isSkipTracking) {
      dispatch(trackPageView({ bibId, list }));
    }

    // Update the active header nav everytime the location changes
    if (window.BCHeaderPublic) {
      window.BCHeaderPublic('setActiveNavItem');
    }
  }, [dispatch, globalList, location, query]);

  useEffect(() => {
    if (!backToLinkEnabled && hydrated) {
      removeSessionStorageItem('backToLink');
    }
  }, [backToLinkEnabled, hydrated]);

  return routes;
}

export default function ApplicationRouter(props) {
  return (
    <Router {...props}>
      <Routes />
    </Router>
  );
}

ApplicationRouter.propTypes = {
  location: PropTypes.string
};
